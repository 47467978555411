import { Checkbox, Radio } from 'antd';
import classNames from 'classnames';
import PeopleWidgetWithOutHolo from 'Components/common/components/PeopleWidgetWithOutHolo';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBuyerStaffPerson } from 'Selector/CompaniesSelector';
import styled from 'styled-components';
import { Executive } from 'Types/PersonTypes';
import { useCollaboratorSelection } from 'Utils/hooks/useCollaboratorSelection';
import { backgroundColorParsing } from 'Utils/UtilityFunctions';
import {
  primaryBgColor,
  primaryColor,
  primaryFont, primaryHeadingColor, secondaryFont, wMedium,
} from 'Utils/utilsStyle';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-height: 55px;
  padding: 10px 10px 10px 0px;
  cursor: pointer;
  .checkbox-search {
    .ant-checkbox-wrapper {
      &:hover {
        .ant-checkbox-inner {
          border-color: #c3c3c3 !important
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            border-color: ${primaryColor} !important;
          }
        }
      }
    }
  }
  .image {
    margin-left: 10px;
    img {
      border-radius: 50%;
      margin: 0 10px;
      width: 35px;
      height: 35px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0px;
    .name {
      color: #000;
      font-size: 14px;
      font-weight: ${wMedium};
      font-family: ${secondaryFont};
      line-height: 15px;
    }
    .email {
      color: ${primaryHeadingColor};
      font-family: ${primaryFont};
      font-size: 12px;
      font-weight: ${wMedium};
      line-height: 14px;
    }
    .designation {
      color: #424B50;
      font-size: 10px;
      font-weight: ${wMedium};
      font-family: ${primaryFont};
      line-height: 14px;
      margin: 2px 0px 0px 0px;
    }
    .company {
      color: #1EC252;
      font-size: 10px;
      font-weight: ${wMedium};
      font-family: ${primaryFont};
      line-height: 14px;
    }
  }
  &.isSelected {
    background-color: ${primaryBgColor};
    cursor: not-allowed !important;
  }
  &.isDisabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  &:hover {
    background-color: ${primaryBgColor};
    cursor: pointer;
  }
`;

type Props = {
  person: Executive;
  isChecked?: boolean;
  radioBtn?: boolean;
  onClickHandler: () => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  checkboxShow?: boolean;
  selectedSapBuyerAnalysis?: Executive
  email?: string;
};

const defaultProps = {
  isSelected: false,
  isDisabled: false,
  isChecked: false,
  radioBtn: false,
  checkboxShow: false,
  selectedSapBuyerAnalysis: {},
  email: '',
};

const AffinitySearchResultItem = function AffinitySearchResultItem(props: Props) {
  const {
    person, onClickHandler, isSelected, isDisabled, isChecked, checkboxShow,
    radioBtn, email,
  } = props;
  const selectedBuyerPerson = useSelector(selectBuyerStaffPerson);
  const { handleCheckBoxClick } = useCollaboratorSelection();

  return (
    <Wrapper className={classNames('affinity-search-results', { isSelected, isDisabled })} {...(isDisabled || isSelected ? {} : { onClick: onClickHandler })}>
      {checkboxShow && !radioBtn ? (
        <div className="checkbox-search">
          <Checkbox
            checked={isChecked}
            onChange={() => { handleCheckBoxClick(person.userId || 0, isChecked || false); }}
          />
        </div>
      ) : null}
      {radioBtn ? (
        <Radio.Group onChange={onClickHandler} value={selectedBuyerPerson}>
          <Radio value={person} />
        </Radio.Group>
      ) : null}

      <div className="image">
        <PeopleWidgetWithOutHolo
          disableNavigation
          diameter="40px"
          diameterSm="40px"
          diameterXsm="40px"
          ringPadding="2px"
          ringPaddingSm="2px"
          marginSm="0px 5px 0px 0px"
          wrapperColor={backgroundColorParsing(person.personalityTypes)}
          logo={person.personImage}
          persona="#F0B520"
          personId={person.personId}
          margin="0 10px"
        />
      </div>
      <div className="content">
        <div className="name text-truncate-one-line">{person.personName}</div>
        <div className="email text-truncate-one-line">{email}</div>
        <div className="designation text-truncate-one-line">{person.companyId === 1880420 ? (person.location?.length ? person.location : person.companyName) : person.personDesignation}</div>
        <div className="company text-truncate-one-line">{person.companyId === 1880420 ? '' : person.companyName}</div>
      </div>
    </Wrapper>
  );
};

AffinitySearchResultItem.defaultProps = defaultProps;
export default AffinitySearchResultItem;
