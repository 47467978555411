import { createGlobalStyle } from 'styled-components';
import {
  primaryColor, primaryFont, primaryHeadingColor, scrollbar, secondaryFont,
  wBold, wMedium, maxWidth, retina, minWidthApp, maxTablet, maxMobile,
} from '../Utils/utilsStyle';

const AlertsStyle = createGlobalStyle`
  .spin-wrap {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    align-item: center;
  }
  .transform-remove {
    transform: translate(-0px) !important;
  }
  .drawer-alerts {
    &.alerts-drawer-index {
      z-index: 5;
    }
    .ant-drawer-body {
      overflow: hidden;
      padding: 0px;
    }
    .ant-drawer-header {
      justify-content: flex-end;
      border-bottom:none;
      padding: 16px 24px 0px 24px;
      .ant-drawer-extra {
        z-index: 2;
        /* width:100%;
        flex: 1 1; */
        .close-alerts{
          &.reason-drawer-close {
            display: flex;
            justify-content: flex-end;
            margin-right: 10px;
          }
          min-width: 33px;
        }
      }
      .ant-drawer-header-title{
        display:none;
      }
    }
    .ant-drawer-body {
      .triggers-wrapper {
        .search-wrapper {
          .ant-input-affix-wrapper {
            .ant-input {
              font-size: 14px;
              @media all and (max-width: ${maxWidth}), ${retina} {
                font-size: 12px;
              }
            }
          }
          .cross-icon  {
            top: 11px;
            right: 10px;
            @media all and (max-width: ${maxWidth}), ${retina} {
              top: 5px;
            }
            @media all and (max-width: 1024px) {
              top: 8px;
            }
          }
        }
      }
    }
  }
  .close-alerts {
    cursor:pointer;
    .anticon-close{
      background-color: #6060604a;
      border-radius: 50%;
      height: 33px;
      width: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      font-size: 18px;
      color: #fff;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        max-width:23px;
      }
    }
  }
  .alerts-head {
    display:flex;
    align-items: flex-end;
    justify-content: space-between;
    .bell-area {
      display:flex;
      align-items: center;
      svg{
        @media all and (max-width: ${maxWidth}) , ${retina} {
          width:22px;
        }
      }
      span{
        svg{
          vertical-align: middle;
        }
      }
    }
   .alerts-heading {
     font-size:40px;
     line-height: 47px;
     color:${primaryHeadingColor};
     font-family: ${secondaryFont};
     font-weight: ${wMedium};
     margin-bottom:0px;
     margin-left:13px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size:28px;
      line-height: 32px;
    }
   }
  }
  .manage-action{
    color: ${primaryColor};
    font-family: ${primaryFont};
    font-size:16px;
    line-height: 24px;
    font-weight: ${wBold};
    cursor:pointer;
    margin-right:10px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size:12px;
      line-height: 17px;
    }
  }
  .ant-drawer-content {
    .article-item {
      .ant-popover-content  {
        .share-icons-wrapper {
          svg {
            width: 16px !important;
            margin-right: 4px;
          }
        }
      }
    }
    .alerts-head, .progress-wrapper {
      padding: 0 60px 16px 60px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        padding: 0 35px 8px 35px;
      }
      @media all and (max-width: ${maxTablet}) {
        padding: 0 10px 8px 10px;
      }
    }
    .profile-area {
      @media all and (max-width: ${maxWidth}) , ${retina} {
        min-width: 50px !important;
      }
    }
    .article-item {
      .profile-area{
        &:before{
                @media all and (max-width: ${maxWidth}) , ${retina} {
                  width: 4px;
                }
              }
            div{
              @media all and (max-width: ${maxWidth}) , ${retina} {
                min-width: 65px;
                width: 60px;
                height: 60px;
                border-radius: 8px;
              }
            }
        }
      }
    }
  .articles-wrapper{
    .article-item {
      @media all and (max-width: ${maxWidth}) , ${retina} {
        border-radius:6px;
        margin-bottom:7px;
      }
    }
  }
  .article-item {
    &.improve-article {
      padding: 7px 10px 7px 5px;
      }
    display:flex;
    align-items:center;
    padding: 5px 10px 5px 5px;
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;
    width: 100%;
    &.active {
      background-color: #EFF5FB;
    }
    .profile-area{
     min-width: 72px;
     margin-right:12px;
     @media all and (max-width:${minWidthApp})
     {
      min-width: 60px;
      width: 60px;
      height: 60px;
     }
    }
    .text-area{
      min-width: calc(100% - 95px);
      @media all and (max-width: ${maxWidth}) , ${retina} {
        min-width: calc(100% - 75px);
      }
    }
    .heading {
      &.heading-width {
        width: 225px;
        margin-left: 12px;
        font-size: 14px;
        line-height: 19px;
        min-height: 25px;
        @media all and (max-width: ${maxWidth}), ${retina} {
          width: 180px;
          font-size: 11px;
          line-height: 14px;
        }
        @media all and (max-width: ${maxTablet}) {
          width: calc(100% - 120px);        
        }
      }
     color:${primaryHeadingColor};
     font-family: ${secondaryFont};
     font-weight: ${wBold};
     margin-bottom:0px;
     font-size:16px;
     line-height:20px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size:12px;
      line-height:14px;
      width:100%;
      }
    }
    .bottom-tips {
      display:flex;
      margin-top:10px;
      width:100%;
      @media all and (max-width: ${maxWidth}) , ${retina} {
       margin-top: 10px;
      }
      .tips{
        font-size:11px;
        line-height:11px;
        font-family: ${primaryFont};
        font-weight: ${wBold};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size:10px;
          line-height:11px;
        }
        &.domain{
          color:#DB3725;
          max-width:40%;
        }
        &.date{
          color: #7E7E7E;
          max-width:30%;
        }
        &.industry{
          color: ${primaryColor};
          max-width:30%;
        }
      }
      .separator {
        border-left: 1.5px solid #7E7E7E;
        border-right: 1.5px solid #7E7E7E;
        padding: 0px 5px;
        margin: 0px 5px;
      }
      .separator-left {
        border-left: 1.5px solid #7E7E7E;
        padding: 0px 5px;
        margin: 0px 5px;
      }
    }
  }
  .articles-wrapper{
    margin:20px 17px 0 60px;
    padding-right:38px;
    height: calc(100vh - 195px) !important;
    ${scrollbar}
    @media all and (max-width: ${maxWidth}) , ${retina} {
      margin:20px 10px 0 35px;
      padding-right:25px;
      padding-bottom:10px;
      height: calc(100vh - 145px) !important;
    }
    @media all and (max-width: ${maxTablet}) {
      margin: 20px 10px 0 10px;
      padding-right: 2px;
      height: calc(100vh - 170px) !important;
    }
    @media all and (max-width: ${maxMobile}) {
      height: calc(100vh - 230px) !important;
    }
  }
  .triggers-wrapper{
    margin:20px 17px 0 60px;
    padding-right:38px;
    height: calc(100vh - 180px);
    border-radius:0px !important;
    ${scrollbar}
    @media all and (max-width: ${maxWidth}) , ${retina} {
      margin:20px 10px 0 35px;
      padding-right:25px;
    }
    @media all and (max-width: ${maxTablet}) {
      margin: 20px 10px 0 10px;
      padding-right: 0px;
    }
    @media all and (max-width: ${maxMobile}) {
      padding-bottom: 50px;
    }
  }
  .peopleAlertsWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .save-alerts {
    display:inline-block;
    padding:5px 18px;
    border-radius:8px;
    background-color: ${primaryColor};
    color:#fff;
    font-family: ${primaryFont};
    font-weight: ${wBold};
    font-size:16px;
    cursor:pointer;
  }
  .heading-panel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .head {
        font-family: ${primaryFont};
        font-weight: ${wMedium};
        color: #67696A;
        font-size:18px;
        line-height:22px;
        margin-bottom:0px;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size: 13px;
        }
      }
      .unselect{
        font-family: ${primaryFont};
        font-weight: ${wMedium};
        color: ${primaryColor};
        font-size:14px;
        line-height:20px;
        cursor:pointer;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          font-size: 12px;
        }
      }
  }
  .trigger-checkbox-area {
    .grid-checkbox-wrapper {
      background-color:#F7F7F7;
      padding: 28px 15px;
      border-radius:10px;
      min-height:427px;
      margin-bottom: 50px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        border-radius:7px;
        padding: 18px 10px;
      }
    }
  }
  .ant-checkbox-wrapper{
    margin-bottom: 10px;
    width:100%;
  }
  .alerts-company-area{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    .progress-wrapper {
      padding: 5px 8px;
    }
    .infinite-scroll-component__outerdiv{
      width: 100%;
    }
  }
  .btn-wrapper{
    .apply-alerts{
      display:inline-block;
      padding:8px 18px;
      border-radius:8px;
      background-color: ${primaryColor};
      color:#fff;
      font-family: ${primaryFont};
      font-weight: ${wBold};
      font-size:16px;
      cursor: pointer;
      width:100%;
      text-align:center;
      margin-bottom:10px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        font-size: 14px;
        padding:6px 18px;
        border-radius:7px;
      }
    }
  }
  .margin-alert-to-center {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .no-test-message {
    height: 100%;
    width: 100%;
    h3 {
      text-align: center;
    }
  }
`;

export default AlertsStyle;
