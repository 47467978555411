import { Modal, Badge, Checkbox } from 'antd';
import classNames from 'classnames';
import BinIconCommon from 'Components/common/assets/svgIcons/BinIconCommon';
import CrossCircle from 'Components/common/assets/svgIcons/CrossCircle';
import PeopleWidgetWithOutHolo from 'Components/common/components/PeopleWidgetWithOutHolo';
import SharedBadge from 'Components/common/components/SharedBadge';
import ShareGroupPeopleModal from 'Components/common/modal/ShareGroupPeopleModal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { selectData } from 'Selector/CertificationSelector';
import {
  selectDeleted, selectDeletingLoader, selectGroupShared,
} from 'Selector/GroupsSelector';
import { actions } from 'Slice/GroupsSlice';
import styled from 'styled-components';
import { GroupPersonality } from 'Types/GroupsTypes';
import { Executive } from 'Types/PersonTypes';
import {
  GROUPDETAILS, GROUP_DETAIL_ROUTE, PEOPLE_ROUTE, translateSkipAreas,
} from 'Utils/Constants';
import { backgroundColorParsing, parseColorGradientMixture, valueToObject } from 'Utils/UtilityFunctions';
import {
  selectAccountSelectedGroups,
  selectCompanyDetailFlurry, selectCompanyDetailsCall, selectShowSelectedGroups,
} from 'Selector/CompaniesSelector';
import { actions as flurryActions } from 'Slice/HelperSlice';
import SmartInputAddIcon from 'Components/common/assets/svgIcons/SmartInputAddIcon';
import SmartInputRemoveIcon from 'Components/common/assets/svgIcons/SmartInputRemoveIcon';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import {
  secondaryFont, maxWidth, retina, wMedium, primaryFont, primaryColor, maxMobile,
  nutenixGradientColor,
} from '../../../Utils/utilsStyle';
import { actions as certificationActions } from '../../../Slice/CertificationSlice';

export type GroupsProps = {
  groupPersona?: GroupPersonality[]
  persons?: Executive[]
  companies?: string[]
  groupName?: string
  groupId?: number
  active?: boolean
  personsCount?: number
  toggleDrawer?: () => void
  shareGroupCheckBox?: boolean,
  isChecked?: boolean,
  onClickHandler: () => void,
  isShared: number,
  sharedBy: string
  sidePanel?: boolean
  showAddIcon?: boolean
  onClickAdd?: () => void
  onClickRemove?: () => void
} | typeof defaultProps;

const defaultProps = {
  groupPersona: [{
    color: '#9797',
    end: '100%',
    percentage: 100,
    start: '0%',
  }],
  groupId: 0,
  persons: [],
  companies: [],
  groupName: 'Not valid group',
  personsCount: 0,
  toggleDrawer() { },
  onClickHandler() { },
  active: false,
  shareGroupCheckBox: false,
  isChecked: false,
  isShared: 0,
  sharedBy: 'None',
  sidePanel: false,
  showAddIcon: false,
  onClickAdd: () => { },
  onClickRemove: () => { },
};
const Wrapper = styled.div<{ color: string, }>`
  background:conic-gradient(from -70deg, ${(props) => props.color});
  padding: 4px;
  border-radius: 20px;
  margin-bottom: 6px;
  position: relative;
  cursor: pointer;
  min-height: 100px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    border-radius: 11px;
    padding: 3px;
  }

  &.smallView {
    min-height: 92px;
    .card-inner-wrapper {
      min-height: 90px;
    }
  }

  .data-share-wrapper-div {
    position: absolute;
    right: 0.5px;
    bottom: 0.5px;
    display: flex;
    align-items: center;
    .share-delt-div {
      height: 34px;
      width: 62px;
      background: #f1f1f1;
      opacity: 1;
      border-radius: 12px 0 15px;
      padding: 8px 10px;
    }
    .shared {
      margin-right: 15px;
    }
  }
  
  .card-inner-wrapper {
    position: relative;
    background-color:#FFFFFF;
    border-radius:16px;
    padding: 10px 0px;
    width:100%;
    min-height: 98px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      border-radius: 9px;
    }
    
    .logo-side {
      width: 20%;
      margin:0px 0px 0px 25px;
    }

    .other-side {
      width: calc(60% - 20px);
      padding-right: 20px;
      padding-left: 25px;
      position: relative;
      margin-bottom: 2px;
      @media all and (max-width: 420px) {
        padding-left: 10px;
      }
      svg {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @media all and (max-width: ${maxWidth}) , ${retina} {
          width:12px;
        }
      }
    }
    .add-remove-div {
      margin-right: 16px;
    }
    .logo-image{
      margin:16px 0 0 -8px;
      img{
        max-height: 25px;
        height:25px;
        width:auto;
        max-width:30px;
        margin:0 2px;
        @media all and (max-width: ${maxMobile}) {
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
    .buyer-name{
      color: #000;
      font-family: ${secondaryFont};
      font-weight:${wMedium};
      font-size: 18px;
      line-height: 25px;
      text-align: left;
      padding:0px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .user-images{
      margin-top:10px;
      margin-bottom:26px;
      position: relative;
      z-index:0;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        margin-top:8px;
        margin-bottom:15px;
      }
      > div{
        padding:2px !important;
        &:nth-child(1){
          z-index:4;
          margin-left: -8px;
          @media all and (max-width: ${maxWidth}) , ${retina} {
            margin-left: -15px;
          }
        }
        &:nth-child(2){
          z-index:3;
          margin-left: -8px;
        }
        &:nth-child(3){
          z-index:2;
          margin-left: -8px;
        }
        &:nth-child(4){
          z-index:1;
          margin-left: -8px;
        }
      }
    }
    .checkbox-div {
      justify-content: end;
      padding-right: 0px;
      margin-left: 20px;
      .ant-checkbox-wrapper {
        margin-bottom: 0px;
      }
    }
    .share-delt-div {
      display: flex;
      align-content: center;
      .share-button-group {
        background: none;
        padding: 0px;
        .icon-mode{
          .icon-mode-text {
            display: none;
          }
          .seperator {
            display: none;
          }
          .share-icon{
            svg {
              height: 20px;
              width: 15px;
              min-width: 15px;
              g {
                fill: #A0A4A7;
              }
              &:hover {
                path {
                  fill: ${primaryColor};
                }
              }
            }
          }
        }
      }
      .delt-div {
        padding: 0px 10px 0px 10px;
        svg {
          width: 15px;
          min-width: 15px;
        }
      }
    }
  }
  .zeroAppend{
    margin-top: -14px;
  }  
`;
const PopupModal = styled(Modal)`
  .ant-modal-content{
    .ant-modal-footer{
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
`;
const CustomBadge = styled(Badge)`
  .ant-badge-multiple-words {
    padding: 0px 0px;
  }
  .ant-badge-count span {
    margin-top: 0px;
  }
  .ant-badge-count{
    z-index: 4;
    font-size: 11px;
    font-family: ${primaryFont};
    background:#424B50;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; 
    height: 25px;
    width: 25px;
    position: absolute;
    top: 15px;
    right: -2px;
    border-radius: 16px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 8px;
      height: 18px;
      width: 18px;
      min-height: 18px;
      min-width: 18px;
      top:10px;
      border-radius: 10px;
    } 
  }
`;

const GroupsDisplayWidget = function GroupsDisplayWidget(props: GroupsProps) {
  const {
    groupPersona,
    persons,
    groupName,
    companies,
    active,
    groupId,
    personsCount,
    toggleDrawer,
    shareGroupCheckBox,
    onClickHandler,
    isChecked,
    isShared,
    sharedBy,
    sidePanel,
    showAddIcon,
    onClickAdd,
    onClickRemove,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deletedGroupId, setDeletedGroupId] = useState(0);
  const deleteLoader = useSelector(selectDeletingLoader);
  const deleted = useSelector(selectDeleted);
  const [searchParams] = useSearchParams();
  const parserId = searchParams.get('groupId');
  const groupIdParsed = parserId ? parseInt(parserId, 10) : 0;
  const groupShared = useSelector(selectGroupShared);
  const data = useSelector(selectData);
  const flurryObject = useSelector(selectCompanyDetailFlurry);
  const isCalled = useSelector(selectCompanyDetailsCall);
  const selectedGroups = useSelector(selectAccountSelectedGroups);
  const showSelectedGroups = useSelector(selectShowSelectedGroups);
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const host = window.location.href;

  useEffect(() => {
    if (isModalVisible && !deleteLoader) {
      setIsModalVisible(deleteLoader);
    }
  }, [deleteLoader]);

  const handleOk = () => {
    if (groupId && !deleteLoader) {
      setDeletedGroupId(groupId);
      dispatch(actions.deleteGroup({ groupId }));
    }
  };
  useEffect(() => {
    if (groupIdParsed !== 0 && deletedGroupId === groupIdParsed && deleted) {
      navigate(`${PEOPLE_ROUTE}`);
    }
  }, [deleted, deletedGroupId]);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const deleteGroup = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  const navigation = () => {
    if (!shareGroupCheckBox && !showAddIcon) {
      if (groupId) {
        if (location.pathname.includes(GROUPDETAILS)) {
          navigate(`${GROUP_DETAIL_ROUTE}${groupId}`, { replace: true });
        } else { navigate(`${GROUP_DETAIL_ROUTE}${groupId}`); }
      }
      if (toggleDrawer) { toggleDrawer(); }
      if (sidePanel) {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('buyerGroups_sidePanel_buyerGroup_clicked', 0, {
            group_id: groupId,
            host,
          }),
        ));
      }
    }
  };

  const shareGroupHandler = (check: boolean) => {
    if (check) {
      dispatch(actions.setSelectedGroups([{ name: groupName || '', id: groupId || 0 }]));
    }
  };

  useEffect(() => {
    if (groupShared) {
      dispatch(actions.setSelectedGroups([]));
    }
  }, [groupShared]);

  const certificationShareAction = () => {
    if (sidePanel) {
      dispatch(certificationActions.certificationAction({
        type: 'buyer_share',
        source: 'buyer',
        data,
      }));
    }
  };

  return (
    <>
      <PopupModal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} centered cancelText="No" okText="Yes" closeIcon={<CrossCircle />} width="530px">
        <p className="text_logout_popup main-message">Are you sure you want to delete the group?</p>
      </PopupModal>
      <Wrapper
        color={disclessPersonality
          ? nutenixGradientColor
          : parseColorGradientMixture(groupPersona || [])}
        className={classNames(
          'group-display-widget',
          { active, smallView: personsCount === 0 },
        )}
        {...(!(shareGroupCheckBox || isChecked) ? {} : { onClick: onClickHandler })}
      >
        <div
          className="card-inner-wrapper"
          role="button"
          tabIndex={-1}
          onClick={navigation}
          onKeyPress={navigation}
        >
          <div className="d-flex align-items-center card-inner-div">
            <div className="logo-side">
              <div className="d-flex logo-image">
                {companies?.slice(0, 3).map((company) => <img src={company} alt="" key={company} />)}
              </div>
              <div className="d-flex user-images">
                {persons?.slice(0, 4).map((person: Executive) => (
                  <PeopleWidgetWithOutHolo
                    logo={person.personImage}
                    persona={person.archeType.color}
                    diameter="30px"
                    diameterSm="21px"
                    diameterXsm="19px"
                    margin="0"
                    wrapperColor={backgroundColorParsing(person.personalityTypes)}
                  />
                ))}
                <CustomBadge
                  showZero
                  overflowCount={99}
                  className={classNames({ zeroAppend: personsCount === 0 })}
                  count={personsCount}
                >
                  <span />
                </CustomBadge>
              </div>
            </div>
            <div className="other-side">
              <div className={`buyer-name text-truncate-two-line ${translateSkipAreas}`}>{groupName}</div>
            </div>
            {showAddIcon ? (
              <div className="add-remove-div">
                {!showSelectedGroups && !selectedGroups.some((group) => group.groupId === groupId)
                  ? <SmartInputAddIcon className="" onClick={() => onClickAdd && onClickAdd()} />
                  : (
                    <SmartInputRemoveIcon
                      onClick={onClickRemove}
                    />
                  )}
              </div>
            ) : null}
            {shareGroupCheckBox ? (
              <div className="checkbox-div">
                <Checkbox checked={isChecked} />
              </div>
            ) : null}

            {!shareGroupCheckBox
              ? (
                <div className="data-share-wrapper-div">
                  {isShared ? <SharedBadge label={`Shared by ${sharedBy}`} /> : null}
                  <div
                    className="share-delt-div"
                    onClick={(e) => e.stopPropagation()}
                    onKeyPress={(e) => e.stopPropagation()}
                    role="button"
                    tabIndex={-1}
                  >
                    <ShareGroupPeopleModal
                      key={groupId}
                      onClickHandler={() => {
                        shareGroupHandler(true);
                        certificationShareAction();
                      }}
                      onCancelHandler={() => shareGroupHandler(false)}
                      iconMode
                    />
                    <div
                      onClick={deleteGroup}
                      onKeyPress={deleteGroup}
                      role="button"
                      tabIndex={-1}
                      className="delt-div"
                    >
                      <BinIconCommon />
                    </div>
                  </div>
                </div>
              ) : null}
          </div>
        </div>
      </Wrapper>
    </>

  );
};

export default GroupsDisplayWidget;
