import { useEffect } from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import CloseAlerts from 'Components/alerts/assets/svg/CloseAlerts';
import {
  primaryFont,
  scrollbar, secondaryFont, wMedium, maxWidth, retina, maxTablet, maxMobile, wLight, wMBold,
} from 'Utils/utilsStyle';
import ArrowLineIcon from 'Components/common/assets/svgIcons/ArrowLineIcon';
import PersonalityPills from 'Components/common/components/PersonalityPills';
import ListItem from 'Components/common/components/ListItem';
import { CheckCircleFilled } from '@ant-design/icons';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  selectInsightsData, personPersonalityInfo, selectPersonalityDrawerOpen,
} from 'Selector/PersonProfileSelector';

import {
  DealingInfo, IBehaviour, IQualities, ITips, PersonalityInfo, PersonalityInsights, PersonInsights,
} from 'Types/PersonTypes';

import classNames from 'classnames';
import PersonalityPillWidget from 'Components/common/components/PersonalityPillWidget';
import DisclaimerMessage from 'Components/common/components/DisclaimerMessage';
import { DISCLAIMER_MESSAGE, DISCLAIMER_MESSAGE_PERSON_PAGE } from 'Utils/Constants';

import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectData } from 'Selector/CertificationSelector';
import { actions as flurryActions } from 'Slice/HelperSlice';
import HTMLReactParser from 'html-react-parser';
import { actions } from '../../../Slice/PersonProfileSlice';
import { getQueryPersonId, getQueryPersonType, valueToObject } from '../../../Utils/UtilityFunctions';

const Wrapper = styled.div<{ personalityColor: string }>`
  position: relative;
  .learnActive {
    display: flex;
    align-items: center;
    @media all and (max-width: ${maxTablet}) {
      font-size: 12px;
    }
    svg {
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }
  }
  .moreOnBtn {
    background-color: ${((props) => props.personalityColor)};
    //box-shadow: 0px 0px 5px ${(props) => props.personalityColor};
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;

const ScrollWrapper = styled.div`
  margin:20px 17px 0 30px;
  padding-right:38px;
  height: calc(100vh - 150px);
  ${scrollbar};
  @media all and (max-width: ${maxWidth}), ${retina}
  {
    height: calc(100vh - 130px);
  }
   @media all and (max-width: ${maxMobile}) {
    height: calc(100vh - 200px);
  }
`;

const Heading = styled.h3`
  font-family: ${secondaryFont};
  font-size: 32px;
  line-height: 36px;
  color: #000;
  font-weight: ${wMedium};
  margin-bottom: 0px;
  padding: 0px 30px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 22px;
    line-height: 26px;
  }
  &.mb-head {
    margin-bottom: 30px;
  }
`;

type Props = {
  personality?: string,
  personaColor?: string,
  isGuestUser?: boolean,
  learnMoreText?: string,
  disk?: string,
  personMainCardDrawer?: boolean,
  personalityName?: string,
} & typeof defaultProps;

const defaultProps = {
  personalityName: '',
  personality: '',
  personaColor: '',
  isGuestUser: false,
  learnMoreText: '',
  disk: '',
  personMainCardDrawer: false,
};

const PersonalityHeading = styled.h3`
  font-family: ${primaryFont};
  font-size: 20px;
  font-weight: ${wMedium};
  line-height:24px;
  letter-spacing: - 0.55px;
  color: #424B50;
  margin: 10px 0px 15px 0px;
  padding-left: 0px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 16px;
    line-height:20px;
  }
`;

const PersonalityParagraph = styled.p`
  font-family: ${primaryFont};
  font-size: 16px;
  font-weight: ${wMedium};
  line-height: 22px;
  letter-spacing: -0.33px;
  color: #67696A;
  margin: 0px 0px 22px 0px;
  padding: 0px;
   @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 12px;
    line-height:18px;
  }
`;

const DiscImage = styled.div<{ color: string }>`
 text-align: center;
 margin: 10px 0px 20px 0px;
 .disc-image-placeholder {
  width: 250px;
  margin: 0 auto;
  box-shadow: 0px 0px 5px ${(props) => props.color};
  border-radius: 50%;
 }
  img {
   height: 100%;
   width: 100%;
   padding: 15px;
  }
`;

const DisclaimerFooter = styled.div`
.disclaimer-text {
  color: #5E7079;
  font-family: ${primaryFont};
  font-size: 10px;
  font-weight: ${wLight};
  line-height: normal;
  letter-spacing: -0.556px;
  .starting-span {
    display: block;
    .primary-color {
      display: inline-block;
      font-weight: ${wMBold};
      font-size: 12px;
    }
    .one-liner-text {
      display: block;
      margin: 5px 0;
    }
  }
  .alphabetical-list-text {
    padding-left: 15px;
    display: block;
    li {
      ::marker {
        font-weight: ${wMBold};
      }
      &:first-child {
        margin-bottom: 3px;
      }
      .numeric-list-text {
        padding-left: 10px;
        margin-top: 3px;
        li {
          margin-bottom: 2px;
        }
      }
    }
  }
}
`;

const DrawerPersonPersonality = function DrawerPersonPersonality(props: Props) {
  const {
    personality, personaColor,
    learnMoreText, disk, personMainCardDrawer, personalityName, isGuestUser,
  } = props;
  const visible = useSelector(selectPersonalityDrawerOpen);

  const personProfilePersonalityInfo: PersonalityInfo = useSelector(personPersonalityInfo);
  const insights: PersonalityInsights[] = useSelector(selectInsightsData);

  const dealingInfo: DealingInfo = insights.find((insight) => insight.type === 'dealingInfo') as DealingInfo;
  const qualities = personProfilePersonalityInfo?.qualities as IQualities;
  const tips: ITips = personProfilePersonalityInfo?.tips;
  const behaviour = personProfilePersonalityInfo?.behaviour as IBehaviour;
  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(actions.setPersonalityDrawerOpen(!visible));
  };
  const data = useSelector(selectData);

  const handleClick = () => {
    if (!visible) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('more_on_person_personality', 0, {
          person_id: getQueryPersonId(),
          type: getQueryPersonType(),
          host: window.location.href,
        }),
      ));
    }
    toggle();
    dispatch(certificationActions.certificationAction({
      type: 'personality_insights',
      source: 'person',
      data,
    }));
  };

  useEffect(() => {
    const node = document.querySelector('body');
    return visible ? node?.classList.add('body-drawer-toggle') : node?.classList.remove('body-drawer-toggle');
  }, [visible]);

  useEffect(() => {
    // Set up the timeout
    if (isGuestUser) {
      const timer = setTimeout(() => {
        toggle();
      }, 5000); // 5000ms = 5 seconds

      // Clean up the timeout when the component unmounts or re-runs
      return () => clearTimeout(timer);
    }
  }, []); // Empty dependency array means this effect runs only on mount

  return (
    <Wrapper personalityColor={personaColor}>
      <span
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex={0}
        role="button"
        aria-label="close"
        className={classNames('personality-div', {
          learnActive: learnMoreText,
          moreOnBtn:
            !personMainCardDrawer && !learnMoreText,
        })}
      >
        {personMainCardDrawer
          ? (
            <PersonalityPillWidget
              persona={personaColor}
              personality={personalityName}
            />
          )
          : (
            <div className="d-flex align-items-center data-text-drawer">
              <span className="personality-text text-truncate">{learnMoreText || `More on “${personality}” personality `}</span>
              <ArrowLineIcon color={personaColor} />
            </div>
          )}
      </span>
      <Drawer
        rootClassName={classNames('drawer-alerts people-group-drawer arctype-drawer')}
        placement="right"
        open={visible}
        onClose={toggle}
        width={530}
        extra={(
          <span onClick={toggle} onKeyPress={toggle} tabIndex={0} role="button" aria-label="close" className="close-alerts"><CloseAlerts /></span>
        )}
      >
        <Heading className="text-truncate-two-line">
          {personality}
          {' '}
          xiQ insights
        </Heading>
        <ScrollWrapper>
          <DiscImage color={personaColor}>
            <div className="disc-image-placeholder">
              <img src={disk} alt="dummy-disc" className="img-fluid" />
            </div>
          </DiscImage>
          <DisclaimerMessage label={DISCLAIMER_MESSAGE} className="disclaimer-style" />
          <PersonalityParagraph className="mb-c-20">
            {behaviour?.description}
          </PersonalityParagraph>

          <PersonalityHeading>
            Qualities
          </PersonalityHeading>
          <div className="tips mb-c-20">
            {qualities?.dataList?.map((quality: string) => (
              <PersonalityPills
                bgColor={personaColor}
                title={quality}
                padding="2px 19px"
                margin="0px 9px 10px 0px"
                fontSize="16px"
                fontSizeSm="12px"
              />
            ))}
          </div>

          <PersonalityHeading>
            Tips:
          </PersonalityHeading>
          <div className="tips-area mb-c-30">
            {tips?.dataList?.map((tip: string) => (
              <ListItem
                key={tip}
                title={tip}
                icon={<CheckCircleFilled style={{ color: personaColor }} />}
              />
            ))}
          </div>
          {dealingInfo?.data?.map((dealing: PersonInsights) => (
            <>
              <PersonalityHeading>
                {dealing?.title}
              </PersonalityHeading>
              <PersonalityParagraph>
                {dealing?.description}
              </PersonalityParagraph>
              <PersonalityHeading>
                Tips:
              </PersonalityHeading>
              <div className="tips-area mb-c-30">
                {dealing?.tips?.map((tip: string) => (
                  <ListItem
                    key={tip}
                    title={tip}
                    icon={<CheckCircleFilled style={{ color: personaColor }} />}
                  />
                ))}
              </div>
            </>
          ))}
          {/* */}
          <DisclaimerFooter className="disclaimer-footer-arch-types">{HTMLReactParser(DISCLAIMER_MESSAGE_PERSON_PAGE)}</DisclaimerFooter>
        </ScrollWrapper>
      </Drawer>
    </Wrapper>
  );
};

DrawerPersonPersonality.defaultProps = defaultProps;
export default DrawerPersonPersonality;
