import { useDispatch, useSelector } from 'react-redux';
import { selectCollabPopupData } from 'Selector/DocumentHubSelector';
import { Executive } from 'Types/PersonTypes';
import { actions } from 'Slice/DocumentHubSlice';
import { PeopleSearchListRequest, SearchListPeople } from 'Types/DocumentHubTypes';

type PeopleType = 'people' | 'collaborators';
export const useCollaboratorSelection = () => {
  const {
    people, collaborators, peopleSearchTerm, collaboratorsSearchTerm,
    currentView, peopleSearchList, collaboratorsSearchList,
    isPeopleSelectAll, isCollaboratorsSelectAll,
    isPeopleSearchSelectAll, isCollaboratorsSearchSelectAll,
    selectedPeople, unSelectedPeople, selectedCollaborators, unSelectedCollaborators,
  } = useSelector(selectCollabPopupData);

  const isCollaborator = currentView === 'collaborators';
  const searchTerm = isCollaborator ? collaboratorsSearchTerm : peopleSearchTerm;
  const selected = isCollaborator ? selectedCollaborators : selectedPeople;
  const unselected = isCollaborator ? unSelectedCollaborators : unSelectedPeople;
  const searchList = isCollaborator ? collaboratorsSearchList : peopleSearchList;
  const isSelectAll = isCollaborator ? isCollaboratorsSelectAll : isPeopleSelectAll;
  const isSearchSelectAll = isCollaborator ? isCollaboratorsSearchSelectAll
    : isPeopleSearchSelectAll;

  const dispatch = useDispatch();

  const headerCheckboxChecked = (persons: Executive[]) => (!persons?.length ? false : persons
    .every((person) => person.isSelected));

  const isHeaderCheckboxChecked = headerCheckboxChecked(isCollaborator ? collaborators : people);

  const handleCheckAll = () => {
    if (!searchTerm?.length) {
      dispatch(actions.resetSelectedPeople(!isHeaderCheckboxChecked));
    } else if (searchTerm?.length) {
      const searchItem = {
        searchTerm,
        isSelectAll: !isHeaderCheckboxChecked,
        ids: [],
        dataKey: currentView as PeopleType,
      };
      dispatch(actions.setIsPeopleSearchSelectAll(!isHeaderCheckboxChecked));
      dispatch(actions.setPeopleSearchSelectionToggle(searchItem));
      dispatch(actions.setSelectAllPeople({
        dataKey: currentView as PeopleType,
        isSelectAll: !isHeaderCheckboxChecked,
      }));
    }
  };

  const updateSelectionWithoutSearch = (id: number, prevSelected: boolean) => {
    if (!isSelectAll && !prevSelected) {
      dispatch(actions.setSelectedPeople([...selected, id]));
      dispatch(actions.addRemoveDocInWholePeopleSearchList({
        isAdd: true,
        id,
      }));
      // dispatch(actions.setSelectedDocumentsCount(prevCount + 1));
      // dispatch(actions.setUnselectedDocumentsCount(unselectedDocumentCount - 1));
    } else if (!isSelectAll && prevSelected) {
      dispatch(actions.setSelectedPeople(selected.filter((sId) => sId !== id)));
      dispatch(actions.addRemoveDocInWholePeopleSearchList({
        isAdd: false,
        id,
      }));
      // dispatch(actions.setSelectedDocumentsCount(prevCount - 1));
      // dispatch(actions.setUnselectedDocumentsCount(unselectedDocumentCount + 1));
    } else if (isSelectAll && prevSelected) {
      dispatch(actions.setUnselectedPeople([...unselected, id]));
      // dispatch(actions.setSelectedDocumentsCount(prevCount - 1));
      // dispatch(actions.setUnselectedDocumentsCount(unselectedDocumentCount + 1));
    } else if (isSelectAll && !prevSelected) {
      dispatch(actions.setUnselectedPeople(unselected.filter((uId) => uId !== id)));
      // dispatch(actions.setSelectedDocumentsCount(prevCount + 1));
      // dispatch(actions.setUnselectedDocumentsCount(unselectedDocumentCount - 1));
    }
  };

  const handleCheckBoxClick = (id: number, isSelected: boolean) => {
    dispatch(actions.setSelectPerson({
      dataKey: currentView as PeopleType,
      id,
    }));
    if (searchTerm?.length) {
      const searchItem = {
        searchTerm,
        isSelectAll: isSearchSelectAll,
        ids: [id],
        isPrevSelected: isSelected,
      };
      const indexWhenIdInOtherObj = searchList.findIndex(
        (item) => !(item.searchTerm === searchTerm)
            && item?.ids?.includes(id),
      );
      if (selected?.includes(id) || unselected?.includes(id)) {
        updateSelectionWithoutSearch(id, isSelected);
      } else if (indexWhenIdInOtherObj !== -1) {
        dispatch(actions.updatePeopleSearchListWhenIdinOtherObj({
          search: searchItem,
          index: indexWhenIdInOtherObj,
        }));
      } else {
        // dispatch(actions.updateSelectedDocCountWithFilter(searchItem));
        dispatch(actions.updatePeopleSearchList({
          ...searchItem,
          selectedDataKey: isCollaborator ? 'selectedCollaborators' : 'selectedPeople',
          unselectedDataKey: isCollaborator ? 'unSelectedCollaborators' : 'unSelectedPeople',
        }));
      }
    } else if (!searchTerm?.length) {
      updateSelectionWithoutSearch(id, isSelected);
    }
  };

  const structureSearchList = (list: SearchListPeople[]): PeopleSearchListRequest[] => list.map(
    (item) => {
      const { ids } = item;
      if (item.isSelectAll) {
        return {
          searchTerm: item.searchTerm,
          isSelectAll: item.isSelectAll,
          selectedIds: '',
          unSelectedIds: ids.join(','),
        };
      }
      return {
        searchTerm: item.searchTerm,
        isSelectAll: item.isSelectAll,
        selectedIds: ids.join(','),
        unSelectedIds: '',
      };
    },
  );

  return {
    isHeaderCheckboxChecked, handleCheckAll, handleCheckBoxClick, structureSearchList,
  };
};
