/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Article } from 'Types/ArticleTypes';
import {
  CompaniesDataResponse,
  CompanyDownloadProfile,
  CompanyIntent,
  CompanySecFilings,
  CompanyStockChart,
  CompanyStaff,
  FollowCompanyResponse,
  RecommendedCompaniesDataResponse,
  RemoveCompanyResponse,
  ExecutivesStaff,
  FollowedStaff,
  AddCompanyPopup,
  NotableStaff,
  ExportRequest,
  NotableStaffResponse,
  CompareCompaniesResponse,
  CompanyComparisonData,
  SWOTResponse,
  SWOTRefresh,
  AccountPlanForm,
  UpdateAccountPlanForm,
  GeneratedAccountPlan,
  SetProductInAccountPlan,
  GenerateAccountPlanToaster,
  AccountPlanV3,
  LiveExecIds,
  RemoveLiveExecId,
  SetAccountPlanHistory,
  SapSortType,
  AccountPlanDocument,
  UpdateSAPCompanyType,
  ChangeSectionCheckboxType,
  UserAccountPlanDetails,
  SapSelectedDoc,
  CompareCompaniesExport,
  AccountPlanStatus,
  AccountPlanStatusPayload,
  Benefit,
} from 'Types/CompaniesTypes';
import { Filter } from 'Types/FilterTypes';
import { CompanyPulse } from 'Types/GlobalSearchResultsTypes';
import { CompaniesState } from 'Types/GlobalTypes';
import { Executive, GlobalSearchPage, WorkbenchStatsResponseType } from 'Types/PersonTypes';
import {
  AccountPlanHistoryRequest,
  AccountPlanStatusRequest,
  AddToFavoriteRequest,
  CompanySWOTAnalysis,
  CompanySWOTRefresh,
  CompareCompaniesRequest,
  DeleteAccountPlanRequest,
  DownloadCompanyProfileRequest,
  ExportCompaniesRequest,
  FollowCompanyRequest,
  GenerateAccountPlanRequest,
  GetAccountPlanRequest,
  GetCompaniesRequest,
  GetCompanyNewsRequest,
  GetDocumentDownloadRequest,
  GetPersonProfileRequest,
  GetProfileRequest,
  NotableStaffRequest,
  RecommendedDesignationsRequest,
} from 'Types/RequestBodies';
import { SetDownloadURL } from 'Types/DocumentHubTypes';
import { StaffBuyerGroupData } from 'Types/GroupsTypes';
import { PersonCardId } from 'Types/ChatBotV2Types';
import { GetCompanyVideosRequest } from '../Types/RequestBodies.d';
import { CompanyProfile } from '../Types/CompaniesTypes.d';

export const initialState: CompaniesState = {
  companyName: '',
  companies: [],
  companiesEnded: false,
  sapAllowed: false,
  companiesPageNumber: 1,
  recommendedCompanies: [],
  searchedCompanies: [],
  companiesLoading: true,
  exportCompaniesLoading: false,
  exportCompaniesUrl: '',
  compareCompaniesLoading: false,
  recommendedCompaniesLoading: false,
  profileLoading: true,
  accountPlanAllowDownload: false,
  companyProfile: null,
  companySecFilingsLoading: true,
  companySecFilings: null,
  companyPulse: null,
  companyPulseLoading: true,
  companyIntent: null,
  companyIntentLoading: true,
  companyStaff: null,
  companyStaffLoading: true,
  filtersArray: [],
  profileDownloadPdfLoading: false,
  profileDownloadpptLoading: false,
  downloadedPDFProfile: null,
  downloadedPPTProfile: null,
  stocksLoading: false,
  stocksData: null,
  addCompanyPopup: {} as AddCompanyPopup,
  companiesCount: 0,
  companyNews: null,
  companyNewsLoading: true,
  companyNewsPageNumber: 1,
  companyNewsEnded: false,
  companyNewsFiltersLoading: true,
  companyNewsFilters: [],
  companyNewsFiltersSelected: '',
  companyBenefits: [],
  notableStaffFilters: [],
  notableStaffFiltersLoading: false,
  companyVideoLoading: false,
  companyBenefitsLoading: false,
  companyVideo: null,
  companyVideoPageNumber: 1,
  videos: false,
  companyVideoEnded: false,
  companyNotableStaff: [],
  notableStaffLoading: false,
  buyerStaffLoading: false,
  notableStaffPage: 1,
  notableStaffCount: 0,
  notableStaffTitle: '',
  notableSelectedFilters: [],
  notableStaffExportLoading: false,
  companyNewsFiltersApplied: '',
  workbenchStats: {
    loading: false,
    isEmptyWorkbenchStats: false,
    data: [] as WorkbenchStatsResponseType[],
  },
  videoApiCall: false,
  socialApiCall: false,
  competitorsApiCall: false,
  finanialApiCall: false,
  companyDetailApiCall: false,
  staffApiCall: false,
  newsApiCall: false,
  socialFlurry: {},
  videoFlurry: {},
  competitorsFlurry: {},
  finanialFlurry: {},
  companyDetailFlurry: {},
  staffFlurry: {},
  newsFlurry: {},
  message: '',
  newsFilterApplied: false,
  companyComparisonData: [],
  comparisonRows: [],
  isComparisonRemoved: false,
  swotLoading: false,
  swotData: {
    createdAt: '',
    swotAnalysis: [],
    painPoints: [],
    refresh: false,
    status: false,
  },
  swotRefreshLoading: false,
  refreshCompanyIds: [],
  swotRefreshedCompany: 0,
  refreshCompanyName: '',
  accountPlanForm: [],
  accountPlanLoading: false,
  accountPlanLoaded: false,
  accountPlanSlideIndex: 0,
  accountPlanUrl: '',
  generationFail: false,
  accountPlanPreviewUrl: '',
  accountPlanFormFilled: false,
  accountFormLoading: false,
  accountPlanIds: [],
  accountPlanAllowed: false,
  accountPlanDrawerOpen: false,
  generatedAccountPlans: [],
  productCategory: '',
  productDescription: '',
  designationsLoading: false,
  designationsLoaded: false,
  smartAccountToastMessage: {
    message: '',
    companyId: 0,
  },
  accountPlan: {
    selectedPeople: [],
    selectedGroups: [],
    groupsToShow: [],
    peopleToShow: [],
    showSelectedPeople: false,
    showSelectedGroups: false,
    designations: [],
    showDesignationsLoadingScreen: true,
    loadingLiveIds: [],
    notableStaff: [],
    notableStaffPage: 1,
    buyerStaffPage: 1,
    buyerStaff: [],
    buyerStaffCount: 0,
    notableStaffCount: 0,
    buyerPerson: {} as Executive,
    liveExecIds: [] as LiveExecIds[],
    buyerPersonId: 0,
    buyerPersonLoading: false,
    showAccountPlanFailed: false,
  },
  companyNotesAvailable: 0,
  newNotes: 0,
  showLimitPopupSAP: false,
  accountPlanHistory: [],
  accountPlanStatus: [],
  accountPlanHistoryLoading: false,
  sapSortType: 'date',
  sapSort: 'des',
  deleteAccountPlanLoading: false,
  currentSapCompanyId: 0,
  showAccountHistory: false,
  showSapPreview: false,
  companiesExported: false,
  accountPlanCompaniesIds: [],
  selectedCompareCompaniesIds: [],
  sortingType: '',
  currentSections: [],
  latestEarningScroll: false,
  fileDownloadURL: '',
  fileViewUrl: '',
  fileShareUrl: '',
  fileShareLoading: false,
};

const CompaniesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setSmartAccountToastMessage(
      state,
      action: PayloadAction<GenerateAccountPlanToaster>,
    ) {
      state.smartAccountToastMessage = action.payload;
    },
    emptySearchedCompanies(state) {
      state.searchedCompanies = [];
    },
    setSearchedCompanies(state, action: PayloadAction<CompaniesDataResponse>) {
      const { payload } = action;
      state.searchedCompanies = payload?.response?.companies;
      state.companiesLoading = false;
    },
    getExportCompanies(state, action: PayloadAction<ExportCompaniesRequest>) {
      state.exportCompaniesLoading = true;
      state.exportCompaniesUrl = '';
    },
    setExportCompanies(state, action: PayloadAction<string>) {
      state.exportCompaniesLoading = false;
      state.exportCompaniesUrl = action.payload;
    },
    resetCompanyComparison(state) {
      state.companyComparisonData = [];
      state.comparisonRows = [];
    },
    compareCompanies(state, action: PayloadAction<CompareCompaniesRequest>) {
      state.compareCompaniesLoading = true;
    },
    setCompareCompanies(
      state,
      action: PayloadAction<CompareCompaniesResponse>,
    ) {
      const { companies, rows } = action.payload;
      let updatedRows = rows;
      for (let i = 0; i < rows.length; i += 1) {
        let hasData = false;
        if (!hasData) {
          for (let j = 0; j < companies.length; j += 1) {
            if (
              companies[j][
              rows[i].type as keyof (typeof companies)[typeof j]
              ]
              && !JSON.stringify(
                companies[j][
                rows[i].type as keyof (typeof companies)[typeof j]
                ],
              ).includes('N/A')
            ) {
              hasData = true;
            }
          }
        }
        if (!hasData) {
          updatedRows = updatedRows.filter(
            (row, index) => row.type !== rows[i].type,
          );
        }
      }
      state.comparisonRows = updatedRows;
      state.companyComparisonData = companies;
      state.compareCompaniesLoading = false;
      state.isComparisonRemoved = false;
    },
    compareCompaniesExport(state, action: PayloadAction<CompareCompaniesRequest>) {
      state.compareCompaniesLoading = true;
    },
    setCompareCompaniesExport(state, action: PayloadAction<CompareCompaniesExport>) {
      const linkO = document.createElement('a');
      linkO.href = action.payload.url;
      linkO.dispatchEvent(new MouseEvent('click'));
      // state.compareCompaniesLoading = false;
      state.companiesExported = true;
    },
    removeComparisonCompany(state, action: PayloadAction<number>) {
      state.companyComparisonData = state.companyComparisonData.filter(
        (company) => company.companyId !== action.payload,
      );
      const companies = state.companyComparisonData;
      const rows = state.comparisonRows;
      let updatedRows = rows;
      for (let i = 0; i < rows.length; i += 1) {
        const hasData = companies.some(
          (company) => company[rows[i].type as keyof CompanyComparisonData]
            && !JSON.stringify(
              company[rows[i].type as keyof CompanyComparisonData],
            ).includes('N/A'),
        );
        if (!hasData) {
          updatedRows = updatedRows.filter(
            (row, index) => row.type !== rows[i].type,
          );
        }
      }
      state.comparisonRows = updatedRows;
      state.isComparisonRemoved = true;
    },
    setIsComparisonRemoved(state, action: PayloadAction<boolean>) {
      state.isComparisonRemoved = action.payload;
    },
    getCompanies(state, action: PayloadAction<GetCompaniesRequest>) {
      state.companiesLoading = true;
      if (action.payload.offset === 0 && !action.payload.searchTerm) {
        state.companies = [];
      }
    },
    setCompanies(state, action: PayloadAction<CompaniesDataResponse>) {
      const { response } = action.payload;
      const { companies, companiesCount } = response;
      if (companies?.length) {
        state.companies = [...state.companies, ...companies];
        state.companiesPageNumber += state.companiesPageNumber;
        state.companiesCount = companiesCount;
        if (state.companies.length) {
          state.companies = state.companies.filter(
            (value, index, self) => index
              === self.findIndex(
                (company) => company.companyId === value.companyId,
              ),
          );
        }
        if (state.companies.length === companiesCount) state.companiesEnded = true;
      } else {
        state.companiesEnded = true;
      }
      state.companiesLoading = false;
    },
    getRecommendedCompanies(state, action: PayloadAction<GetCompaniesRequest>) {
      state.companiesLoading = true;
      state.recommendedCompaniesLoading = true;
      state.recommendedCompanies = [];
    },
    setRecommendedCompanies(
      state,
      action: PayloadAction<RecommendedCompaniesDataResponse>,
    ) {
      const { payload } = action;
      state.recommendedCompanies = payload?.response?.recommendedCompanies;
      state.recommendedCompaniesLoading = false;
    },
    followCompany(state, actions: PayloadAction<FollowCompanyRequest>) {
      const { payload } = actions;
      const { companyId } = payload;
      const index = state.recommendedCompanies.findIndex(
        (company) => company.companyId === parseInt(companyId.trim(), 10),
      );
      if (index !== -1) {
        state.recommendedCompanies[index].loading = true;
      }
    },
    handleCompanyFollowed(state, action: PayloadAction<FollowCompanyResponse>) {
      const { companyIds, popUpResponse, subcribedCompaniesCount } = action.payload.response;
      state.companiesCount = subcribedCompaniesCount;
      if (popUpResponse.showPlanPopup === 8) {
        state.addCompanyPopup = popUpResponse;
        const { companies, recommendedCompanies } = state;
        for (let i = 0; i < companyIds.length; i += 1) {
          const index = recommendedCompanies.findIndex(
            (company) => company.companyId === parseInt(companyIds[i].trim(), 10),
          );
          if (index !== -1) {
            recommendedCompanies[index].loading = false;
            state.recommendedCompanies = recommendedCompanies;
          }
        }
      } else {
        const { companies, recommendedCompanies } = state;
        for (let i = 0; i < companyIds.length; i += 1) {
          const index = recommendedCompanies.findIndex(
            (company) => company.companyId === parseInt(companyIds[i].trim(), 10),
          );
          if (index !== -1) {
            recommendedCompanies[index].companySubscribers += 1;
            recommendedCompanies[index].loading = false;
            if (popUpResponse.showPlanPopup === 0) {
              recommendedCompanies[index].companySubscribed = true;
              companies.unshift(recommendedCompanies[index]);
              companies[companies.length - 1].companySubscribed = true;
              recommendedCompanies.splice(index, 1);
            }
          }
        }
        const companyProfileCopy = state.companyProfile as CompanyProfile;

        if (
          companyProfileCopy
          && companyIds.length === 1
          && parseInt(companyIds[0], 10) === companyProfileCopy.companyId
          && !companyProfileCopy.companySubscribed
        ) {
          companyProfileCopy.companySubscribed = true;
          companyProfileCopy.userCompanyFollowers += 1;
          state.companyProfile = companyProfileCopy;
        }
        state.companies = companies;
        state.recommendedCompanies = recommendedCompanies;
        state.addCompanyPopup = popUpResponse;
      }
      if (state.companyComparisonData.length > 0) {
        const index = state.companyComparisonData.findIndex(
          (company) => company.companyId === parseInt(companyIds[0].trim(), 10),
        );
        if (index !== -1) {
          state.companyComparisonData[index].companySubscribed = true;
        }
      }
    },
    unFollowCompanies(state, actions: PayloadAction<FollowCompanyRequest>) {
      const { payload } = actions;
      const { companyId } = payload;
      if (companyId) {
        const index = state.companies.findIndex(
          (company) => company.companyId === parseInt(companyId.trim(), 10),
        );
        if (index !== -1) {
          state.companies[index].loading = true;
        }
      }
    },
    handleCompanyUnFollowed(
      state,
      action: PayloadAction<RemoveCompanyResponse>,
    ) {
      const { companyIds, subcribedCompaniesCount } = action.payload.response;
      const { companies } = state;
      state.companiesCount = subcribedCompaniesCount;
      for (let i = 0; i < companyIds.length; i += 1) {
        const index = companies.findIndex(
          (company) => company.companyId === parseInt(companyIds[i].trim(), 10),
        );
        if (index !== -1) {
          state.companies[index].loading = false;
          state.companies[index].companySubscribed = false;
          state.companies[index].companySubscribers -= 1;
          state.recommendedCompanies.unshift(companies[index]);
          companies.splice(index, 1);
        }
      }
      const companyProfileCopy = state.companyProfile as CompanyProfile;
      if (
        companyProfileCopy
        && companyIds.length === 1
        && parseInt(companyIds[0], 10) === companyProfileCopy.companyId
        && companyProfileCopy.companySubscribed
      ) {
        companyProfileCopy.companySubscribed = false;
        companyProfileCopy.userCompanyFollowers -= 1;
        state.companyProfile = companyProfileCopy;
      }
      if (state.companyComparisonData.length > 0) {
        const index = state.companyComparisonData.findIndex(
          (company) => company.companyId === parseInt(companyIds[0].trim(), 10),
        );

        if (index !== -1) {
          state.companyComparisonData[index].companySubscribed = false;
        }
      }
      state.companies = companies;
    },
    getCompanyProfile(state, action: PayloadAction<GetProfileRequest>) {
      state.profileLoading = true;
    },
    setCompanyProfile(state, action: PayloadAction<CompanyProfile | null>) {
      state.companyProfile = action.payload;
      state.accountPlanUrl = action.payload?.accountPlan.fileUrl || '';
      state.accountPlanPreviewUrl = action.payload?.accountPlan.viewableUrl || '';
      state.accountPlanAllowed = action.payload?.accountPlan.isAllowed || false;
      state.sapAllowed = action?.payload?.sapAllowed || false;
      state.profileLoading = false;
      state.newNotes = action.payload?.newNotes ?? 0;
      state.companyNotesAvailable = action.payload?.companyNotes || 0;
      state.accountPlanAllowDownload = action.payload?.userAccountPlanDetails?.allowDownload ?? false;
    },
    setCompanyNotesAvailable(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.companyNotesAvailable = payload;
    },
    resetNewNotes(state) {
      state.newNotes = 0;
    },
    getCompanyBenefits(state, action: PayloadAction<GetProfileRequest>) {
      state.companyBenefitsLoading = true;
    },
    setCompanyBenefits(state, action: PayloadAction<Benefit[]>) {
      state.companyBenefits = action.payload;
    },
    getStockChart(state, action: PayloadAction<GetProfileRequest>) {
      state.stocksLoading = true;
    },
    setStockChart(state, action: PayloadAction<CompanyStockChart>) {
      state.stocksLoading = false;
      state.stocksData = action.payload;
    },
    getCompanySecFilings(state, action: PayloadAction<GetProfileRequest>) {
      state.companySecFilingsLoading = true;
    },
    setCompanySecFilings(
      state,
      action: PayloadAction<CompanySecFilings | null>,
    ) {
      state.companySecFilingsLoading = false;
      state.companySecFilings = action.payload;
    },
    getCompanyPulse(state, action: PayloadAction<GetProfileRequest>) {
      state.companyPulseLoading = true;
    },
    setCompanyPulse(state, action: PayloadAction<CompanyPulse[] | null>) {
      state.companyPulseLoading = false;
      state.companyPulse = action.payload;
    },
    getCompanyIntentData(state, action: PayloadAction<GetProfileRequest>) {
      state.companyIntentLoading = true;
    },
    setCompanyIntentData(state, action: PayloadAction<CompanyIntent | null>) {
      state.companyIntentLoading = false;
      state.companyIntent = action.payload;
    },
    getCompanyStaff(state, action: PayloadAction<GetProfileRequest>) {
      if (!state.companyStaff) {
        state.companyStaffLoading = true;
        state.companyNotableStaff = [];
        state.notableStaffLoading = false;
        state.notableStaffPage = 1;
        state.notableStaffTitle = '';
        state.notableStaffFilters = [];
        state.notableStaffFiltersLoading = true;
      }
    },
    setCompanyStaff(state, action: PayloadAction<CompanyStaff>) {
      state.companyStaffLoading = false;
      state.companyStaff = action.payload;
    },

    removeCompanyStaffBuyerGroup(state, action: PayloadAction<number>) {
      if (state.companyStaff) {
        state.companyStaff.response[0].data = state.companyStaff.response[0].data.filter(
          (g) => g.groupId !== action.payload,
        );
      }
    },

    addCompanyStaffBuyerGroup(
      state,
      action: PayloadAction<StaffBuyerGroupData[]>,
    ) {
      if (
        action.payload.length > 0
        && state.companyStaff
        && !state.companyStaff.response[0].data.find(
          (d) => d.groupId === action.payload[0].groupId,
        )
      ) {
        state.companyStaff.response[0].data = [
          action.payload[0],
          ...state.companyStaff.response[0].data,
        ];
      }
    },

    handleFollowUnfollowPeople(state, action: PayloadAction<number[]>) {
      const personIds = action.payload;
      const index = state.companyStaff?.response?.findIndex(
        (staffType) => staffType.type === 'executives',
      );
      const indexOfFollowedStaff = state.companyStaff?.response?.findIndex(
        (staffType) => staffType.type === 'followedStaff',
      );
      if (index && index !== -1) {
        const staffTypeFiltered = state.companyStaff?.response[
          index
        ] as ExecutivesStaff;
        let staffIfollowType = {} as FollowedStaff;
        if (indexOfFollowedStaff && indexOfFollowedStaff !== -1) {
          staffIfollowType = state.companyStaff?.response[
            indexOfFollowedStaff
          ] as FollowedStaff;
        }
        const notableStaff = state.companyNotableStaff;
        notableStaff?.map((p) => {
          const person = p;
          person.location = person.location ?? '';
          if (person.personId === personIds[0]) {
            person.personAdded = !person.personAdded;
            if (
              indexOfFollowedStaff
              && indexOfFollowedStaff !== -1
              && person.personAdded
            ) {
              if (staffIfollowType && staffIfollowType.data) {
                staffIfollowType?.data.push(person as Executive);
              }
            }
          }
          return person;
        });
        for (let i = 0; i < personIds.length; i += 1) {
          for (let j = 0; j < staffTypeFiltered.data.length; j += 1) {
            const personInfo = staffTypeFiltered.data[j];
            if (personInfo.personId === personIds[i]) {
              personInfo.personAdded = !personInfo.personAdded;
              if (
                indexOfFollowedStaff
                && indexOfFollowedStaff !== -1
                && personInfo.personAdded
              ) {
                staffIfollowType?.data.push(personInfo);
              }
            }
          }
        }
        if (state.companyStaff) {
          state.companyStaff.response[index] = staffTypeFiltered;
        }
        if (
          state.companyStaff
          && indexOfFollowedStaff
          && indexOfFollowedStaff !== -1
        ) {
          state.companyStaff.response[indexOfFollowedStaff] = staffIfollowType;
        }
        state.companyNotableStaff = notableStaff;
      }
    },
    handleFollowUnfollowStaffIFollow(state, action: PayloadAction<number[]>) {
      const personIds = action.payload;
      const index = state.companyStaff?.response?.findIndex(
        (staffType) => staffType.type === 'followedStaff',
      );
      if (index && index !== -1) {
        const staffTypeFiltered = state.companyStaff?.response[
          index as number
        ] as FollowedStaff;
        for (let i = 0; i < personIds.length; i += 1) {
          for (let j = 0; j < staffTypeFiltered.data.length; j += 1) {
            const personInfo = staffTypeFiltered.data[j];
            if (personInfo.personId === personIds[i]) {
              staffTypeFiltered.data.splice(j, 1);
            }
          }
        }
        if (state.companyStaff) {
          state.companyStaff.response[index as number] = staffTypeFiltered;
        }
      }
    },
    followGroupPeople(state) {
      const { response } = state.companyStaff || {};
      const companyStaff = response?.find(
        (staffType) => staffType.type === 'executives',
      ) as ExecutivesStaff;
      const staffIFollow = response?.find(
        (staffType) => staffType.type === 'followedStaff',
      ) as FollowedStaff;
      companyStaff?.data?.forEach((staff) => {
        const person = staff;
        person.personAdded = true;
      });
      companyStaff?.data?.forEach((person) => {
        staffIFollow.data.push(person);
      });
      if (staffIFollow?.data) {
        staffIFollow.data = staffIFollow?.data?.filter(
          (value, index, self) => index === self.findIndex((t) => t.personId === value.personId),
        );
      }
    },
    handleFollowGroupPeople(state, action: PayloadAction<number>) {
      const { payload } = action;
      const { response } = state.companyStaff || {};
      const companyStaff = response?.find(
        (staffType) => staffType.type === 'executives',
      ) as ExecutivesStaff;
      const staffIFollow = response?.find(
        (staffType) => staffType.type === 'followedStaff',
      ) as FollowedStaff;
      companyStaff?.data?.forEach((staff) => {
        const person = staff;
        if (person.personId === payload) {
          person.personAdded = true;
        }
      });
      if (!staffIFollow?.data?.some((person) => person.personId === payload)) {
        companyStaff?.data?.forEach((person) => {
          if (person.personId === payload) {
            staffIFollow?.data.push(person);
          }
        });
      }
      state.companyNotableStaff?.forEach((staff) => {
        const person = staff;
        if (person.personId === payload) {
          person.personAdded = true;
        }
      });
    },
    handlePeopleFollow(state, action: PayloadAction<CompanyStaff>) {
      state.companyStaffLoading = false;
      state.companyStaff = action.payload;
    },
    setCompanyFilterArray(state, action: PayloadAction<Filter[]>) {
      state.filtersArray = action.payload;
    },
    getPDFProfile(state, action: PayloadAction<DownloadCompanyProfileRequest>) {
      state.profileDownloadPdfLoading = true;
    },
    setPDFProfile(state, action: PayloadAction<CompanyDownloadProfile | null>) {
      state.profileDownloadPdfLoading = false;
      state.downloadedPDFProfile = action.payload;
      const { payload } = action;
      if (payload) {
        const {
          showPlanPopup, popupHeading, popupText, contactusEmail,
        } = payload;
        if (showPlanPopup) {
          if (state.companyProfile) {
            const { userAccountDetails } = state.companyProfile as CompanyProfile;
            userAccountDetails.showPlanPopup = showPlanPopup;
            userAccountDetails.popUpHeading = popupHeading;
            userAccountDetails.popUpText = popupText;
            userAccountDetails.contactUsEmail = contactusEmail;
            userAccountDetails.isDownloadPopup = true;
            state.companyProfile.userAccountDetails = userAccountDetails;
          }
        } else {
          const linkO = document.createElement('a');
          linkO.href = payload.url;
          const name = state.companyName;
          linkO.download = `${name.replace(' ', '')}_xiQ_Profile.pdf`;
          linkO.dispatchEvent(new MouseEvent('click'));
          state.downloadedPDFProfile = null;
        }
      }
    },
    getPPTProfile(state, action: PayloadAction<DownloadCompanyProfileRequest>) {
      state.profileDownloadpptLoading = true;
    },
    setPPTProfile(state, action: PayloadAction<CompanyDownloadProfile | null>) {
      state.profileDownloadpptLoading = false;
      state.downloadedPPTProfile = action.payload;
      const { payload } = action;
      if (payload) {
        const {
          showPlanPopup, popupHeading, popupText, contactusEmail,
        } = payload;
        if (showPlanPopup) {
          if (state.companyProfile) {
            const { userAccountDetails } = state.companyProfile as CompanyProfile;
            userAccountDetails.showPlanPopup = showPlanPopup;
            userAccountDetails.popUpHeading = popupHeading;
            userAccountDetails.popUpText = popupText;
            userAccountDetails.contactUsEmail = contactusEmail;
            userAccountDetails.isDownloadPopup = true;
            state.companyProfile.userAccountDetails = userAccountDetails;
          }
        } else {
          const linkO = document.createElement('a');
          linkO.href = payload.url;
          const name = state.companyName;
          linkO.download = `${name.replace(' ', '')}_xiQ_Profile.ppt`;
          linkO.dispatchEvent(new MouseEvent('click'));
          state.downloadedPPTProfile = null;
        }
      }
    },
    resetCompanyProfilePopup(state) {
      if (state.companyProfile) {
        const { userAccountDetails } = state.companyProfile as CompanyProfile;
        userAccountDetails.showPlanPopup = 0;
        userAccountDetails.contactUsEmail = '';
        userAccountDetails.callToAction = '';
        userAccountDetails.isDownloadPopup = false;
        state.companyProfile.userAccountDetails = userAccountDetails;
      }
    },
    buyerGroupsPopup(state, action: PayloadAction<AddCompanyPopup>) {
      state.addCompanyPopup = action.payload;
    },
    resetAddCompanyPopup(state) {
      if (state.addCompanyPopup) {
        state.addCompanyPopup.showPlanPopup = 0;
        state.addCompanyPopup.popupHeading = '';
        state.addCompanyPopup.popupText = '';
        state.addCompanyPopup.contactUsEmail = '';
        state.addCompanyPopup.callToAction = '';
      }
    },
    getCompanyNews(state, action: PayloadAction<GetCompanyNewsRequest>) {
      state.companyNewsLoading = true;
      if (state.companyNewsPageNumber === 1) {
        state.companyNewsFiltersLoading = true;
        state.companyNews = [];
      }
    },
    setCompanyNews(state, action: PayloadAction<Article[] | null>) {
      state.companyNewsLoading = false;
      if (state.companyNews === null || state.companyNewsPageNumber === 1) {
        state.companyNews = action.payload;
      } else if (action.payload) {
        state.companyNews = [...state.companyNews, ...action.payload];
      }
      state.companyNewsPageNumber += 1;
      state.companyNewsEnded = !action.payload?.length;
    },
    setNewsFilters(state, action: PayloadAction<Filter[]>) {
      state.companyNewsFiltersLoading = false;
      if (state.companyNewsFilters.length !== action.payload.length) {
        state.companyNewsFilters = action.payload;
      }
    },
    setCompanyNewsPageNumber(state, action: PayloadAction<number>) {
      state.companyNewsPageNumber = action.payload;
    },
    setCompanyNewsFiltersSelected(state, action: PayloadAction<string>) {
      state.companyNewsFiltersSelected = action.payload;
    },
    resetCompanyNews(state, action) {
      state.companyNews = null;
      state.companyNewsLoading = true;
      state.companyNewsPageNumber = 1;
      state.companyNewsEnded = false;
      state.companyNewsFiltersLoading = true;
      state.companyNewsFilters = [];
      state.companyNewsFiltersSelected = '';
    },

    getCompanyVideos(state, action: PayloadAction<GetCompanyVideosRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        state.companyVideo = null;
        state.companyVideoPageNumber = 1;
        state.videos = true;
      }
      state.companyVideoLoading = true;
    },

    setCompanyVideos(state, action: PayloadAction<Article[] | null>) {
      state.companyVideoLoading = false;
      if (state.companyVideo === null || state.companyVideoPageNumber === 1) {
        state.companyVideo = action.payload;
      } else if (action.payload) {
        state.companyVideo = [...state.companyVideo, ...action.payload];
      }
      state.companyVideoPageNumber += 1;
      state.companyVideoEnded = !action.payload?.length;
    },
    resetCompaniesData(state, action: PayloadAction<object>) {
      state.companiesLoading = false;
      state.companies = [];
      state.companiesEnded = false;
      state.companiesPageNumber = 1;
    },
    resetRecommendedCompaniesData(state, action: PayloadAction<object>) {
      state.recommendedCompanies = [];
      state.recommendedCompaniesLoading = false;
    },
    getNotableStaff(state, action: PayloadAction<NotableStaffRequest>) {
      const { payload } = action;
      if (payload?.screenName === 'sap') {
        if (payload.page === 1) {
          state.accountPlan.notableStaff = [];
          state.accountPlan.notableStaffPage = payload.page;
          state.accountPlan.notableStaffCount = 0;
        }
      } else {
        if (payload.page === 1) {
          state.companyNotableStaff = [];
          state.notableStaffPage = payload.page;
          state.notableStaffCount = 0;
        }
        if (action.payload.filters) {
          state.notableSelectedFilters = action.payload.filters;
        } else {
          state.notableSelectedFilters = [];
        }
      }
      state.notableStaffLoading = true;
    },
    getBuyerStaff(state, action: PayloadAction<NotableStaffRequest>) {
      const { payload } = action;
      if (payload.page === 1) {
        state.accountPlan.buyerStaff = [];
        state.accountPlan.buyerStaffPage = payload.page;
        state.accountPlan.buyerStaffCount = 0;
      }
      state.buyerStaffLoading = true;
    },
    setNotableStaff(state, action: PayloadAction<NotableStaff>) {
      if (state.notableStaffPage === 1) {
        state.notableStaffFilters = action.payload.filters;
        state.notableStaffTitle = action.payload.title;
        state.companyName = action.payload.companyName;
        state.companyNotableStaff = action.payload.data;
        state.notableStaffCount = action.payload.count;
      } else {
        state.companyNotableStaff = state.companyNotableStaff.concat(
          action.payload.data,
        );
      }
      state.notableStaffPage += 1;
      state.notableStaffLoading = false;
      state.notableStaffFiltersLoading = false;
    },
    setSapNotableStaff(state, action: PayloadAction<NotableStaff>) {
      if (state.accountPlan.notableStaffPage === 1) {
        state.notableStaffFilters = action.payload.filters;
        state.notableStaffTitle = action.payload.title;
        state.companyName = action.payload.companyName;
        state.accountPlan.notableStaff = action.payload.data;
        state.accountPlan.notableStaffCount = action.payload.count;
      } else {
        state.accountPlan.notableStaff = state.accountPlan.notableStaff.concat(
          action.payload.data,
        );
      }
      state.accountPlan.notableStaffPage += 1;
      state.notableStaffFiltersLoading = false;
      state.notableStaffLoading = false;
    },
    setSapBuyerStaff(state, action: PayloadAction<NotableStaff>) {
      if (state.accountPlan.buyerStaffPage === 1) {
        state.accountPlan.buyerStaff = action.payload.data;
        state.accountPlan.buyerStaffCount = action.payload.count;
      } else {
        state.accountPlan.buyerStaff = state.accountPlan.buyerStaff.concat(
          action.payload.data,
        );
      }
      state.accountPlan.buyerStaffPage += 1;
      state.buyerStaffLoading = false;
    },
    getExportNotableStaff(state, action: PayloadAction<ExportRequest>) {
      state.notableStaffExportLoading = true;
    },
    setExportNotableStaff(state, action: PayloadAction<NotableStaffResponse>) {
      const { payload } = action;
      state.notableStaffExportLoading = false;
      const linkO = document.createElement('a');
      linkO.href = payload.url;
      linkO.dispatchEvent(new MouseEvent('click'));
    },
    resetExportNotableStaff(state) {
      state.notableStaffExportLoading = false;
    },
    setCompanyNewsFiltersApplied(state, action: PayloadAction<string>) {
      state.companyNewsFiltersApplied = action.payload;
    },
    getCompanyWorkbenchStats(state, action: PayloadAction<number>) {
      if (state.workbenchStats.loading === false) {
        state.workbenchStats.loading = true;
      }
      state.workbenchStats.data = [];
      state.workbenchStats.isEmptyWorkbenchStats = false;
    },
    getDocumentDownloadURL(state, action: PayloadAction<GetDocumentDownloadRequest>) {
      state.fileDownloadURL = '';
      state.fileViewUrl = '';
      state.fileShareUrl = '';
      state.fileShareLoading = true;
    },
    setDocumentDownloadURL(state, action: PayloadAction<SetDownloadURL>) {
      const { fileURL, fileViewUrl } = action.payload;
      state.fileDownloadURL = fileURL;
      state.fileViewUrl = fileViewUrl;
      state.fileShareUrl = fileURL;
    },
    removeDocumentDownloadURL(state) {
      state.fileDownloadURL = '';
      state.fileShareUrl = '';
      state.fileShareLoading = false;
    },
    setCompanyWorkbenchStats(
      state,
      action: PayloadAction<WorkbenchStatsResponseType[]>,
    ) {
      const { payload } = action;
      if (payload) {
        state.workbenchStats.loading = false;
        state.workbenchStats.data = payload;
        state.workbenchStats.isEmptyWorkbenchStats = false;
      }
    },
    setEmptyCompanyWorkbenchStats(state) {
      state.workbenchStats.loading = false;
      state.workbenchStats.data = [];
      state.workbenchStats.isEmptyWorkbenchStats = true;
    },
    failCompanyWorkbenchStats(state, action: PayloadAction<string>) {
      if (state.workbenchStats.loading) {
        state.workbenchStats.loading = false;
      }
      state.workbenchStats.isEmptyWorkbenchStats = false;
    },
    resetProfileDownload(state) {
      state.profileDownloadPdfLoading = false;
      state.profileDownloadpptLoading = false;
      state.downloadedPDFProfile = null;
      state.downloadedPPTProfile = null;
    },
    setCompetitorsCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.competitorsApiCall = payload;
    },
    setFinanialCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.finanialApiCall = payload;
    },
    setCompanyDetailCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.companyDetailApiCall = payload;
    },
    setSocialCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.socialApiCall = payload;
    },
    setVideoCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.videoApiCall = payload;
    },
    setStaffCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.staffApiCall = payload;
    },
    setNewsCall(state, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.newsApiCall = payload;
    },
    setCompetitorsFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.competitorsFlurry = payload;
    },
    setFinanialFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.finanialFlurry = payload;
    },
    setCompanyDetailFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.companyDetailFlurry = payload;
    },
    setSocialFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.socialFlurry = payload;
    },
    setVideoFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.videoFlurry = payload;
    },
    setStaffFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.staffFlurry = payload;
    },
    setNewsFlurry(state, action: PayloadAction<object>) {
      const { payload } = action;
      state.newsFlurry = payload;
    },
    addToFavoriteSuccess(state, action: PayloadAction<AddToFavoriteRequest>) {
      const { articleId, favoriteFlag } = action.payload;
      if (articleId) {
        if (state.companyNews && state.companyNews.length) {
          const matchedArticles = state.companyNews.filter(
            (article) => article.articleId === articleId,
          );
          if (matchedArticles.length > 0) {
            matchedArticles.forEach((arrayItem) => {
              const article = arrayItem;
              article.isFavorite = favoriteFlag === 1;
            });
          }
        }
      }
    },
    setNewsFilterApplied(state) {
      state.newsFilterApplied = true;
    },
    resetNewsFilterApplied(state) {
      state.newsFilterApplied = false;
    },
    handlePersonNotes(state, action: PayloadAction<number>) {
      const id = action.payload;
      const { response } = state.companyStaff || {};
      const companyStaffData = response?.find(
        (staffType) => staffType.type === 'executives',
      )?.data as Executive[];
      const staffIFollowData = response?.find(
        (staffType) => staffType.type === 'followedStaff',
      )?.data as Executive[];
      const companyNotableStaff = state.companyNotableStaff as Executive[];

      if (companyStaffData && staffIFollowData) {
        [...companyStaffData, ...staffIFollowData]?.map((p) => {
          const person = p;
          if (person.personId === id) {
            person.personNotes = 0;
          }
          return person;
        });
      }
      if (companyNotableStaff) {
        companyNotableStaff?.map((p) => {
          const person = p;
          if (person.personId === id) {
            person.personNotes = 0;
          }
          return person;
        });
      }
    },
    getSWOTAnalysis(state, action: PayloadAction<CompanySWOTAnalysis>) {
      state.swotLoading = true;
    },
    setSWOTAnalysis(state, action: PayloadAction<SWOTResponse>) {
      state.swotLoading = false;
      state.swotData = action.payload;
    },
    setSWOTAnalysisFailed(state) {
      state.swotLoading = false;
      state.swotData = initialState.swotData;
    },
    refreshSWOTAnalysis(state, action: PayloadAction<CompanySWOTRefresh>) {
      const { companyId } = action.payload;
      state.swotRefreshLoading = true;
      state.refreshCompanyIds = [...state.refreshCompanyIds, companyId];
    },
    setRefreshSWOTAnalysis(state, action: PayloadAction<SWOTRefresh>) {
      const { status, flag } = action.payload;
      state.swotRefreshLoading = false;
      state.swotData.status = status;
      if (flag === 'updated') {
        state.swotData.refresh = true;
      }
    },
    setSwotRefreshedCompany(state, action: PayloadAction<number>) {
      state.swotRefreshedCompany = action.payload;
    },
    removeRefreshCompanyId(state, action: PayloadAction<number>) {
      state.refreshCompanyIds = state.refreshCompanyIds.filter(
        (id) => id !== action.payload,
      );
    },
    setRefreshCompanyName(state, action: PayloadAction<string>) {
      state.refreshCompanyName = action.payload;
    },
    setRefreshSWOTLoading(state, action: PayloadAction<boolean>) {
      state.swotRefreshLoading = action.payload;
    },
    getAccountPlanForm(state, action: PayloadAction<GetAccountPlanRequest>) {
      state.accountFormLoading = true;
    },
    setAccountPlanForm(state, action: PayloadAction<AccountPlanForm[]>) {
      state.accountFormLoading = false;
      state.currentSapCompanyId = 0;
      state.accountPlanForm = action.payload;
      state.productCategory = action.payload[0].questions[0]
        .userInput as string;
      state.productDescription = action.payload[0].questions[1]
        .userInput as string;
      state.generationFail = false;
    },
    setAccountFormLoading(state, action: PayloadAction<boolean>) {
      state.accountFormLoading = action.payload;
    },
    // setting url
    setUpdatedUrl(
      state,
      action: PayloadAction<string>,
    ) {
      state.accountPlanUrl = action.payload;
    },
    setUpdatedUserAccountPlanDetails(
      state,
      action: PayloadAction<UserAccountPlanDetails>,
    ) {
      if (state.companyProfile) {
        state.companyProfile.userAccountPlanDetails = action.payload;
      }
    },
    generateAccountPlan(
      state,
      action: PayloadAction<GenerateAccountPlanRequest>,
    ) {
      state.accountPlanLoading = true;
      state.accountPlanLoaded = false;
      state.accountPlanSlideIndex = 0;
      state.accountPlanIds = [
        ...state.accountPlanIds,
        action.payload.companyId,
      ];
      state.accountPlan = {
        selectedPeople: [],
        selectedGroups: [],
        groupsToShow: [],
        peopleToShow: [],
        showSelectedPeople: false,
        showSelectedGroups: false,
        designations: [],
        showDesignationsLoadingScreen: true,
        loadingLiveIds: [],
        notableStaff: [],
        notableStaffPage: 1,
        notableStaffCount: 0,
        buyerStaffPage: 1,
        buyerStaff: [],
        buyerStaffCount: 0,
        buyerPerson: {} as Executive,
        liveExecIds: [] as LiveExecIds[],
        buyerPersonId: 0,
        buyerPersonLoading: false,
        showAccountPlanFailed: false,
      };
    },
    updateSAPCompany(state, action: PayloadAction<UpdateSAPCompanyType>) {
      const { prevCompany, newCompany } = action.payload;
      const form = [...state.accountPlanForm];
      const index = form[3].questions[0]?.competitors?.findIndex(
        (c) => c.companyId === prevCompany?.companyId,
      );
      if (prevCompany === null) {
        form[3].questions[0].competitors?.push(newCompany);
      } else if (index !== undefined && index !== -1 && form[3]?.questions[0]?.competitors) {
        form[3].questions[0].competitors[index] = newCompany;
      }
      state.accountPlanForm = form;
    },
    updateSAPDocSection(state, action: PayloadAction<ChangeSectionCheckboxType>) {
      const { type, value } = action.payload;
      const form = [...state.accountPlanForm];
      const index = form[4].questions[0]?.sections?.findIndex(
        (c) => c.type === type,
      );
      if (index !== undefined && index !== -1 && form[4]?.questions[0]?.sections) {
        form[4].questions[0].sections[index].selected = value;
        state.accountPlanForm = form;
      }
    },
    updateSAPDocSelection(state, action: PayloadAction<SapSelectedDoc>) {
      const form = [...state.accountPlanForm];
      form[1].questions[0].documents = action.payload;
      state.accountPlanForm = form;
    },
    generateAccountPlanIds(state, action: PayloadAction<number>) {
      state.accountPlanLoading = true;
      state.accountPlanLoaded = false;
      state.accountPlanIds = [...state.accountPlanIds, action.payload];
    },
    setAccountPlan(state, action: PayloadAction<GeneratedAccountPlan>) {
      const { companyId, url, previewUrl } = action.payload;
      state.accountPlanLoading = false;
      state.accountPlanLoaded = true;
      const generatedAccountPlan = {
        companyId,
        url,
        previewUrl,
      };
      state.generatedAccountPlans = [
        ...state.generatedAccountPlans,
        generatedAccountPlan,
      ];
      // state.accountPlanUrl = url;
    },
    getAccountPlanStatus(state, action: PayloadAction<AccountPlanStatusPayload>) {
      console.log(action.payload, 'action.payload');
    },

    removeAccountPlanId(state, action: PayloadAction<number>) {
      state.accountPlanIds = state.accountPlanIds.filter(
        (id) => id !== action.payload,
      );
      state.accountPlanCompaniesIds = state.accountPlanCompaniesIds.filter((id) => id !== action.payload);
      state.accountPlanFormFilled = false;
    },
    setAccountPlanLoading(state, action: PayloadAction<boolean>) {
      state.accountPlanLoading = action.payload;
    },
    setAccountPlanSlideIndex(state, action: PayloadAction<number>) {
      state.accountPlanSlideIndex = action.payload;
    },
    setLatestEarningScroll(state, action: PayloadAction<boolean>) {
      if (action.payload === true) {
        state.companyNewsLoading = true;
      }
      state.latestEarningScroll = action.payload;
    },
    updateAccountPlanForm(
      state,
      action: PayloadAction<UpdateAccountPlanForm[]>,
    ) {
      const userInputForm = action.payload.filter(
        (input) => input.userSelection === 1
          || (typeof input.userInput === 'string'
            && input.userInput?.trim()?.length > 0)
          || input.userInput?.length
          || input.userInput === ''
          || input.optionIndex !== undefined,
      );
      const updatedForm = [...state.accountPlanForm];
      userInputForm.forEach((input) => {
        const {
          questionStatement,
          title,
          userSelection,
          optionIndex,
          userInput,
          id,
        } = input;
        const formToUpdate = updatedForm[state.accountPlanSlideIndex];
        const questionToUpdate = formToUpdate.questions.find(
          (question) => question.statement === questionStatement
            || formToUpdate.questions.indexOf(question) + 1 === id,
        );
        if (title && userSelection !== undefined) {
          const optionToUpdate = questionToUpdate?.options.find(
            (option) => option.title === title,
          );
          if (optionToUpdate) {
            optionToUpdate.userSelection = userSelection;
            optionToUpdate.userInput = userInput;
          }
        } else if (optionIndex !== undefined) {
          questionToUpdate?.options.forEach((option, index) => {
            if (index === optionIndex) {
              questionToUpdate.options[index].userSelection = 1;
              questionToUpdate.options[index].userInput = userInput;
            } else {
              questionToUpdate.options[index].userSelection = 0;
              questionToUpdate.options[index].userInput = '';
            }
          });
        } else if (questionToUpdate) {
          if (userInput.length) {
            questionToUpdate.userSelection = 1;
          } else {
            questionToUpdate.userSelection = 0;
          }
          questionToUpdate.userInput = userInput;
        }
      });
      state.accountPlanForm = updatedForm;
      if (state.accountPlanSlideIndex === state.accountPlanForm.length - 1) {
        state.accountPlanFormFilled = true;
      }
    },
    setCompaniesIds(state, action: PayloadAction<number>) {
      if (state.accountPlanSlideIndex === state.accountPlanForm.length - 1) {
        state.accountPlanCompaniesIds = [...state.accountPlanCompaniesIds, action.payload];
      }
    },
    resetAccountPlan(state) {
      state.accountPlanForm = [];
      // state.accountPlanLoading = false;
      state.accountPlanLoaded = false;
      state.accountPlanSlideIndex = 0;
      state.accountPlanUrl = '';
      state.accountPlanPreviewUrl = '';
      state.accountPlanFormFilled = false;
      state.accountPlanHistory = [];
      state.accountPlanCompaniesIds = [];
      state.accountPlanStatus = [];
      state.accountPlan = {
        selectedPeople: [],
        selectedGroups: [],
        groupsToShow: [],
        peopleToShow: [],
        showSelectedPeople: false,
        showSelectedGroups: false,
        designations: [],
        showDesignationsLoadingScreen: true,
        loadingLiveIds: [],
        notableStaff: [],
        notableStaffPage: 1,
        buyerStaffPage: 1,
        buyerStaff: [],
        buyerStaffCount: 0,
        notableStaffCount: 0,
        buyerPerson: {} as Executive,
        liveExecIds: [] as LiveExecIds[],
        buyerPersonId: 0,
        buyerPersonLoading: false,
        showAccountPlanFailed: false,
      };
    },
    setNewAccountPlan(state) {
      state.accountPlanFormFilled = false;
      state.accountPlanLoaded = false;
      state.accountPlanSlideIndex = 0;
      state.generatedAccountPlans = [];
      if (state.companyProfile) {
        state.companyProfile.accountPlan.status = 'Not Generated';
      }
    },
    getRecommendedDesignations(
      state,
      action: PayloadAction<RecommendedDesignationsRequest>,
    ) {
      state.designationsLoading = true;
      state.designationsLoaded = false;
    },
    setRecommendedDesignations(state, action: PayloadAction<string[]>) {
      state.accountPlan.designations = [...action.payload, ...['']];
      state.designationsLoading = false;
      state.designationsLoaded = true;
    },
    updateDesignations(state, action: PayloadAction<string[]>) {
      state.accountPlan.designations = action.payload;
    },
    setDesignationsLoaded(state, action: PayloadAction<boolean>) {
      state.designationsLoaded = action.payload;
    },
    setProductCategory(state, action: PayloadAction<string>) {
      state.productCategory = action.payload;
    },
    setProductDescription(state, action: PayloadAction<string>) {
      state.productDescription = action.payload;
    },
    setAccountPlanDrawerOpen(state, action: PayloadAction<boolean>) {
      state.accountPlanDrawerOpen = action.payload;
    },
    setProductInAccountPlan(
      state,
      action: PayloadAction<SetProductInAccountPlan>,
    ) {
      const { productCategory, productDescription } = action.payload;
      const updatedAccountPlanForm = state.accountPlanForm.map((step) => {
        const updatedQuestions = step.questions.map((question, index) => {
          if (index === 0) {
            return {
              ...question,
              userInput: productCategory,
            };
          }
          if (index === 1) {
            return {
              ...question,
              userInput: productDescription,
            };
          }
          return question;
        });

        return {
          ...step,
          questions: updatedQuestions,
        };
      });
      state.accountPlanForm = updatedAccountPlanForm;
    },
    addAccountSelectedPeople(state, action: PayloadAction<Executive>) {
      state.accountPlan.selectedPeople = [
        ...state.accountPlan.selectedPeople,
        action.payload,
      ];
    },
    addAccountSelectedGroup(state, action: PayloadAction<StaffBuyerGroupData>) {
      state.accountPlan.selectedGroups = [
        ...state.accountPlan.selectedGroups,
        action.payload,
      ];
    },
    removeAccountSelectedPeople(state, action: PayloadAction<Executive>) {
      const execId = state.accountPlan.liveExecIds.find(
        (obj) => obj.liveId === action.payload.personId,
      );
      state.accountPlan.selectedPeople = state.accountPlan.selectedPeople.filter(
        (person) => person.personId
          !== (execId?.executiveId || action.payload.personId)
          && person.personId !== (execId?.liveId || action.payload.personId),
      );
    },
    removeAccountSelectedGroup(
      state,
      action: PayloadAction<StaffBuyerGroupData>,
    ) {
      state.accountPlan.selectedGroups = state.accountPlan.selectedGroups.filter(
        (group) => group.groupId !== action.payload.groupId,
      );
    },
    setShowSelectedPeople(state, action: PayloadAction<boolean>) {
      state.accountPlan.showSelectedPeople = action.payload;
    },
    setShowSelectedGroup(state, action: PayloadAction<boolean>) {
      state.accountPlan.showSelectedGroups = action.payload;
    },
    setGroupsToShow(state, action: PayloadAction<StaffBuyerGroupData[]>) {
      state.accountPlan.groupsToShow = action.payload;
    },
    deleteGroup(state, action: PayloadAction<number>) {
      state.accountPlan.groupsToShow = state.accountPlan.groupsToShow.filter((g) => g.groupId !== action.payload);
    },
    setPeopleToShow(state, action: PayloadAction<Executive[]>) {
      state.accountPlan.peopleToShow = action.payload;
    },
    setBuyerPeopleToShow(state, action: PayloadAction<GlobalSearchPage>) {
      if (action.payload.page === 1) {
        state.accountPlan.buyerStaff = action.payload.data;
      } else {
        state.accountPlan.buyerStaff = state.accountPlan.buyerStaff.concat(
          action.payload.data,
        );
      }
      state.buyerStaffLoading = false;
    },
    setSelectedBuyerPerson(state, action: PayloadAction<Executive>) {
      state.accountPlan.buyerPerson = action.payload;
    },
    resetBuyerPage(state, action: PayloadAction<number>) {
      state.accountPlan.buyerStaffPage = action.payload;
    },
    resetBuyerCount(state, action: PayloadAction<number>) {
      state.accountPlan.buyerStaffCount = action.payload;
    },
    setShowDesignationsLoadingScreen(state, action: PayloadAction<boolean>) {
      state.accountPlan.showDesignationsLoadingScreen = action.payload;
    },
    getPersonProfile(state, action: PayloadAction<GetPersonProfileRequest>) {
      state.accountPlan.buyerPersonLoading = true;
      state.accountPlan.loadingLiveIds = [
        ...state.accountPlan.loadingLiveIds,
        action.payload.personId,
      ];
    },
    setLivePersonAccountPlan(state, action: PayloadAction<PersonCardId>) {
      state.accountPlan.buyerPersonLoading = false;
      const { livesearchId, executiveId } = action.payload;
      const duplicateRemoved = state.accountPlan.selectedPeople.filter(
        (person) => person.personId !== executiveId,
      );
      const updated = duplicateRemoved.map((person) => (person.personId === livesearchId
        ? { ...person, personId: executiveId, personType: 'EXECUTIVE' }
        : person));
      state.accountPlan.selectedPeople = updated;
      state.accountPlan.loadingLiveIds = state.accountPlan.loadingLiveIds.filter((id) => id !== livesearchId);
    },
    setLiveExecIds(state, action: PayloadAction<LiveExecIds>) {
      state.accountPlan.buyerPersonLoading = false;
      state.accountPlan.liveExecIds = [
        ...state.accountPlan.liveExecIds,
        action.payload,
      ];
    },
    setBuyerPersonId(state, action: PayloadAction<PersonCardId>) {
      const { livesearchId, executiveId } = action.payload;
      state.accountPlan.loadingLiveIds = state.accountPlan.loadingLiveIds.filter((id) => id !== livesearchId);
      state.accountPlan.buyerPersonLoading = false;
      state.accountPlan.buyerPersonId = executiveId;
    },
    removeLiveExecIds(state, action: PayloadAction<RemoveLiveExecId>) {
      const { id, type } = action.payload;
      if (type === 'LIVESEARCH') {
        state.accountPlan.liveExecIds = state.accountPlan.liveExecIds?.filter(
          (savedId) => savedId.liveId !== id,
        );
      } else {
        state.accountPlan.liveExecIds = state.accountPlan.liveExecIds?.filter(
          (savedId) => savedId.executiveId !== id,
        );
      }
    },
    removeLivePersonLoadingId(state, action: PayloadAction<number>) {
      state.accountPlan.loadingLiveIds = state.accountPlan.loadingLiveIds.filter((id) => id !== action.payload);
    },
    setShowLimitPopupSAP(state, action: PayloadAction<boolean>) {
      state.showLimitPopupSAP = action.payload;
    },
    getAccountPlanHistory(state, action: PayloadAction<AccountPlanHistoryRequest>) {
      state.accountPlanHistoryLoading = true;
      if (!action.payload.offset) {
        state.accountPlanHistory = [];
      }
    },
    setAccountPlanHistory(state, action: PayloadAction<SetAccountPlanHistory>) {
      const { offset, accountPlanHistory } = action.payload;
      const sapHistory = accountPlanHistory.filter((obj) => Object.keys(obj).length !== 0);
      if (offset) {
        state.accountPlanHistory = [...state.accountPlanHistory, ...sapHistory];
      } else {
        state.accountPlanHistory = sapHistory;
      }
      state.accountPlanHistoryLoading = false;
    },

    setAccountPlanStatus(state, action: PayloadAction<AccountPlanStatus[]>) {
      state.accountPlanStatus = action.payload;
    },
    toggleSort(state, action: PayloadAction<SapSortType>) {
      state.accountPlanHistoryLoading = true;
      if (action.payload === state.sapSortType) {
        if (state.sapSort === 'asc') state.sapSort = 'des';
        else state.sapSort = 'asc';
      } else {
        state.sapSortType = action.payload;
        state.sapSort = 'des';
      }
      state.accountPlanHistory = [];
    },
    deleteAccountPlan(state, action: PayloadAction<DeleteAccountPlanRequest>) {
      state.deleteAccountPlanLoading = true;
    },
    setDeleteAccountPlan(state, action: PayloadAction<number>) {
      state.accountPlanHistory = state.accountPlanHistory.filter(
        (sap) => sap.id !== action.payload,
      );
      state.deleteAccountPlanLoading = false;
    },
    setDeleteAccountPlanLoading(state, action: PayloadAction<boolean>) {
      state.deleteAccountPlanLoading = action.payload;
    },
    setCurrentSapCompanyId(state, action: PayloadAction<number>) {
      state.currentSapCompanyId = action.payload;
    },
    setShowSAPHistory(state, action: PayloadAction<boolean>) {
      state.showAccountHistory = action.payload;
    },
    setAccountPlanFailed(state, action: PayloadAction<boolean>) {
      state.accountPlan.showAccountPlanFailed = action.payload;
    },
    setShowSapPreview(state, action: PayloadAction<boolean>) {
      state.showSapPreview = action.payload;
    },
    setGenerationFail(state) {
      state.generationFail = true;
    },
    selectedCompareCompaniesIds(state, action: PayloadAction<number>) {
      state.selectedCompareCompaniesIds.push(action.payload);
    },
    removeCompareCompaniesIds(state, action: PayloadAction<number>) {
      state.selectedCompareCompaniesIds = state.selectedCompareCompaniesIds
        .filter((company) => company !== action.payload);
    },
    setSortingType(state, action: PayloadAction<string>) {
      state.sortingType = action.payload;
      state.companiesLoading = true;
      state.companiesEnded = false;
      state.companies = [];
    },
    resetCompareCompaniesIds(state) {
      state.selectedCompareCompaniesIds = [];
    },
    pushCurrentSection(state, action: PayloadAction<string>) {
      const item = action.payload;
      if (item === 'null') {
        state.currentSections = [];
      } else {
        if (!state.currentSections.includes(item)) {
          state.currentSections.push(item);
        }
        if (item === 'Overview') {
          const index = state.currentSections.indexOf('Sales Triggers');
          if (index !== -1) {
            state.currentSections.splice(index, 1);
          }
        }
      }
    },
    removeCurrentSection(state, action: PayloadAction<string>) {
      const item = action.payload;
      const index = state.currentSections.indexOf(item);
      if (index !== -1) {
        state.currentSections.splice(index, 1);
      }
    },
    reset: (state) => ({
      ...initialState,
      swotRefreshLoading: state.swotRefreshLoading,
      refreshCompanyIds: state.refreshCompanyIds,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = CompaniesSlice;
