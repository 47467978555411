import { Drawer, Progress } from 'antd';
import styled from 'styled-components';
import CloseAlerts from 'Components/alerts/assets/svg/CloseAlerts';
import {
  primaryHeadingColor,
  scrollbar, secondaryFont, wMBold, maxWidth, retina,
  primaryFont, primaryColor, wLight, certificationBot, wMedium,
} from 'Utils/utilsStyle';
import { useDispatch, useSelector } from 'react-redux';
import Logo from 'Components/layout/assets/Logo';
import { useEffect } from 'react';
import {
  selectAiCertificationData,
  selectAiCertificationLoading,
  selectData, selectIsAiCertificationCompleted, selectIsCertificationCompleted,
  selectLoading, selectOpenGilroyDrawer,
} from 'Selector/CertificationSelector';
import {
  CERTIFICATION_FOOTER_PARAGRAPH, CERTIFICATION_FOOTER_PARAGRAPH_GILROY,
  GILROY_CERTIFICATION, SALES_X_CERTIFICATION,
} from 'Utils/Constants';
import SkeletonCustom from 'Components/common/skeleton/SkeletonCustom';
import { backgroundColorParsing } from 'Utils/UtilityFunctions';
import PeopleWidgetWithOutHolo from 'Components/common/components/PeopleWidgetWithOutHolo';
import { Executive } from 'Types/PersonTypes';
import { selectPeopleDrawerOpen } from 'Selector/BuyerGroupSelector';
import { selectGroupDrawerOpen } from 'Selector/GroupsSelector';
import { selectPersonalityDrawerOpen } from 'Selector/PersonProfileSelector';
import { selectCopyModalOpen } from 'Selector/ProfileShareSelector';
import HTMLReactParser from 'html-react-parser';
import classNames from 'classnames';
import { selectShowGlobalGilroy } from 'Selector/GlobalSearchContentSelector';
import CustomizedPanel from './CustomizedPanel';
import CongratulationDiv from './CongratulationDiv';
import { actions } from '../../../Slice/CertificationSlice';
import { actions as personActions } from '../../../Slice/PersonProfileSlice';
import { actions as buyergroupActions } from '../../../Slice/BuyerGroupsPageSlice';
import { actions as groupActions } from '../../../Slice/GroupsSlice';
import { actions as shareActions } from '../../../Slice/ProfileShareSlice';

type Props = {
  visible?: boolean
} & typeof defaultProps;

const defaultProps = {
  visible: false,
};

const Wrapper = styled.div`
  position: relative;
  .create-group {
    padding: 0px 30px;
  }
`;

const Heading = styled.h3`
  font-family: ${secondaryFont};
  font-size: 28px;
  line-height: 44px;
  color: ${primaryHeadingColor};
  font-weight: ${wMBold};
  margin-bottom: 10px;
  padding: 0px;
  text-align: center;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 24px;
    line-height: 30px;
  }
`;

const ScrollBar = styled.div`
  margin:0 17px 0 30px;
  padding-right: 10px;
  height: calc(100vh - 145px);
  ${scrollbar};
  border-radius: 0px !important;  
  @media all and (max-width: ${maxWidth}), ${retina} {
    margin: 0 17px 0 30px;
    height: calc(100vh - 115px);
  }
  .progress-bar-div {
    &.progressGilroy {
      @media all and (max-width: ${maxWidth}), ${retina} {
        padding: 0 30px 0 0;
      }
      .percentage {
        @media all and (max-width: ${maxWidth}), ${retina} {
          margin-left: -72px !important;
        }
      }
    }
  }
  .percentage{
    font-family: ${primaryFont};
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: -72px;
    margin-right: 80px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      margin-left: -35px;
      margin-right: 35px;
    }
  }
  .ant-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-progress-outer {
      width: 375px;
      padding-right: 40px;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        width: 325px;
      }
    }
    .ant-progress-bg {
      background-color: #E79540;
    }
    &.ant-progress-status-success {
      .ant-progress-bg {
        background-color: ${primaryColor};
      }
    }
    .ant-progress-text {
      display:none;
    }
    .ant-progress-inner {
      height: 10px;
      .ant-progress-bg {
        height: 10px !important;
      }
    }
    .anticon-check-circle {
      svg {
        margin-top: 5px;
        font-size: 16px;
      }
    }
  }
`;

const CustomLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  span{
    margin-top: 15px;
    width:500px;
    height:60px;
  }
  @media all and (max-width: ${maxWidth}) , ${retina} {
    span{
      width:350px;
      height:60px;
      margin-top: 20px;
    }
  }
`;

const LogoArea = styled.div`
  text-align: center;
  svg {
    height: 80px;
    width: 120px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      height: 50px;
    }
  }
`;
const FooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0px 30px;
  .person-display-area {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    .person-without-holo {
      .person-image {
        cursor: unset;
      }
      &:not(:first-child) {
        margin-left: -10px;
      }
      &:nth-child(1){
        z-index: 3;
      }
      &:nth-child(2){
        z-index: 2;
      }
      &:nth-child(3){
        z-index: 1;
      }
    }
    .moreCount {
      margin-left: 10px;
      font-size: 14px;
      font-weight: ${wMedium};
      font-family: ${primaryFont};
    }
  }
`;
const GilRoyImg = styled.img`
  width: 84px;
  height: 64px;
`;
const FooterHeading = styled.span`
  font-family: ${secondaryFont};
  font-weight: ${wMBold};
  font-size: 18px;
  color: ${primaryHeadingColor};
  line-height: 24px;
  margin-top:13px;
  text-align: center;
  &.paragraph-text {
    font-weight: ${wLight};
    font-size: 14px;
    line-height: 20px;
    color:#A0A0A0;
    margin-top: 24px;
  }
`;

const FooterCongratulation = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0px 30px;
`;

const DrawerCertification = function DrawerCertification(props: Props) {
  const {
    visible,
  } = props;
  const data = useSelector(selectData);
  const aiCertificationData = useSelector(selectAiCertificationData);
  const isGilroyDrawer = useSelector(selectOpenGilroyDrawer);
  const certificationData = isGilroyDrawer ? aiCertificationData : data;
  const { persons, personCount, companyName } = certificationData;
  const loading = useSelector(selectLoading);
  const aiLoading = useSelector(selectAiCertificationLoading);
  const completed = useSelector(selectIsCertificationCompleted);
  const aiCompleted = useSelector(selectIsAiCertificationCompleted);
  const dispatch = useDispatch();
  const headingGilroy = `Be the first from ${companyName} to get <span class="primary-color">xiQ GPT <sup>TM</sup></span> certified`;
  const heading = isGilroyDrawer ? headingGilroy : `Be the first from ${companyName} to get certified`;
  const peopleDrawerOpen = useSelector(selectPeopleDrawerOpen);
  const groupDrawerOpen = useSelector(selectGroupDrawerOpen);
  const personalityDrawerOpen = useSelector(selectPersonalityDrawerOpen);
  const copyModalOpen = useSelector(selectCopyModalOpen);
  const allowGlobalGilroy = useSelector(selectShowGlobalGilroy);
  const certificationComplete = isGilroyDrawer ? aiCompleted : completed;

  useEffect(() => {
    if (!data.data.length && !loading) {
      dispatch(actions.getCertificationData({}));
    }
  }, []);

  useEffect(() => {
    if (!aiCertificationData?.data?.length && !aiLoading && allowGlobalGilroy) {
      dispatch(actions.getCertificationData({
        type: 'ai_cert',
      }));
    }
  }, [allowGlobalGilroy]);

  const setToggle = () => {
    dispatch(actions.setOpenDrawer(false));
  };

  const getPercentage = () => {
    if (certificationComplete) {
      return 100;
    }
    return Math.floor(certificationData.percentage);
  };

  useEffect(() => {
    const node = document.querySelector('body');
    return visible ? node?.classList.add('body-drawer-toggle-certification') : node?.classList.remove('body-drawer-toggle-certification');
  }, [visible]);

  useEffect(() => {
    if (visible) {
      if (groupDrawerOpen) {
        dispatch(groupActions.setGroupDrawerOpen(false));
      }
      if (peopleDrawerOpen) {
        dispatch(buyergroupActions.setPeopleDraweropen(false));
      }
      if (personalityDrawerOpen) {
        dispatch(personActions.setPersonalityDrawerOpen(false));
      }
      if (copyModalOpen) {
        dispatch(shareActions.setCopyModalOpen(false));
      }
    }
  }, [visible]);

  return (
    <Wrapper>
      <Drawer
        rootClassName={classNames('drawer-alerts certification-drawer', { gilroyCertification: isGilroyDrawer })}
        placement="right"
        open={visible}
        onClose={setToggle}
        width={600}
        extra={(
          <span
            onClick={setToggle}
            onKeyPress={setToggle}
            tabIndex={0}
            role="button"
            aria-label="close"
            className="close-alerts"
          >
            <CloseAlerts />
          </span>
        )}
      >

        <LogoArea>
          <Logo />
        </LogoArea>

        {(loading && !isGilroyDrawer) || (aiLoading && isGilroyDrawer) ? (
          <CustomLoader>
            {
              [1, 2, 3, 4].map(() => (
                <SkeletonCustom
                  width="350px"
                  height="60px"
                />
              ))
            }

          </CustomLoader>
        ) : (
          <ScrollBar className="groups-wrapper" id="sales-x">
            <Heading>
              {HTMLReactParser(isGilroyDrawer ? GILROY_CERTIFICATION : SALES_X_CERTIFICATION)}
            </Heading>
            <div className={classNames('progress-bar-div d-flex justify-content-center align-items-center', { progressGilroy: isGilroyDrawer })}>
              <Progress
                percent={getPercentage()}
                size="small"
                success={{ strokeColor: '#1EC252' }}
              />
              <span className="percentage">{`${getPercentage()}%`}</span>
            </div>
            <CustomizedPanel
              certificationData={certificationData}
            />
            {certificationComplete ? (
              <FooterCongratulation>
                <CongratulationDiv />
              </FooterCongratulation>
            ) : null}
            {!personCount && !certificationComplete ? (
              <FooterDiv>
                <GilRoyImg alt="gilroy" src={certificationBot} />
                <FooterHeading>{HTMLReactParser(heading)}</FooterHeading>
                <FooterHeading className="paragraph-text">{isGilroyDrawer ? CERTIFICATION_FOOTER_PARAGRAPH_GILROY : CERTIFICATION_FOOTER_PARAGRAPH}</FooterHeading>
              </FooterDiv>
            )
              : (
                <FooterDiv>
                  {personCount
                    ? (
                      <>
                        <div className="person-display-area person-overlap-wrapper">
                          {persons.map((person: Executive) => (
                            <PeopleWidgetWithOutHolo
                              key={person.personId}
                              logo={person.personImage}
                              diameter="90px"
                              diameterSm="90px"
                              diameterXsm="90px"
                              imageClassName="person-image"
                              marginSm="0px 0px 0px 0px"
                              margin="0px 0px 0px 0px"
                              wrapperColor={backgroundColorParsing(person.personalityTypes)}
                              personId={person.personId}
                              ringPadding="3px"
                              ringPaddingSm="4px"
                              disableNavigation
                            />
                          ))}
                          {personCount > 3 ? (
                            <span className="moreCount">
                              +
                              {personCount - persons.length}

                            </span>
                          ) : ''}
                        </div>
                        <FooterHeading>
                          {personCount > 1 ? `${personCount} people from ${companyName} are certified` : `${personCount} person from ${companyName} is certified`}
                        </FooterHeading>
                      </>
                    ) : null}
                  <FooterHeading className="paragraph-text">{CERTIFICATION_FOOTER_PARAGRAPH}</FooterHeading>
                </FooterDiv>
              )}
          </ScrollBar>
        )}
      </Drawer>
    </Wrapper>
  );
};

DrawerCertification.defaultProps = defaultProps;
export default DrawerCertification;
