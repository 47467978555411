/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */

import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { actions } from 'Slice/PeopleSlice';
import { API } from 'Utils/Constants';

import { ExportFollowPeopleResponse, RecommendedPeopleResponse } from 'Types/PersonTypes';

import axios from 'Utils/Axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { ExportFollowRequest, FollowedPeopleRequest } from 'Types/RequestBodies';

import { FollowedResponse, FollowedPeopleResponse } from 'Types/CompaniesTypes';
import { Filter } from 'Types/FilterTypes';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { getCurrentTime, valueToObject } from 'Utils/UtilityFunctions';

export function* getFollowedPeople(dispatched: PayloadAction<FollowedPeopleRequest>) {
  const startTime = getCurrentTime();
  try {
    // const selectedFilter = filters.filter((item) =>
    // item.isSelected).map((item) => item.filterId).join(',');
    const payload = dispatched?.payload;
    const data: any = {
      offset: payload.offset,
      ...payload.filters && payload.filters.length > 0 && { filters: payload.filters?.map((it: Filter) => it.filterId).join(',') },
      ...payload.query && { query: payload.query },
    };
    const response: FollowedPeopleResponse = yield call(
      axios.post,
      API.GET_FOLLOWED_PEOPLE,
      data,
    );
    if (response?.status === true) {
      const pass: FollowedResponse = response?.response[0];
      if (payload.offset) {
        yield put(flurryActions.callFlurryEvent(
          valueToObject(
            'get_followed_people_loaded',
            startTime,
            {
              offset: payload.offset,
              ...payload.filters && payload.filters.length > 0 && payload.filters !== undefined
            && { filters: payload.filters?.map((it: Filter) => it.filterId).join(',') },
              ...payload.query !== '' && payload.query !== undefined && { query: payload.query },
              host: window.location.href,
            },
          ),
        ));
      }

      if (dispatched?.payload?.offset !== 0) {
        yield put(actions.setMoreFollowedPeople(pass));
      } else { yield put(actions.setInitialFollowedPeople(pass)); }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getRecommendedPeople() {
  try {
    const response: RecommendedPeopleResponse = yield call(
      axios.post,
      API.GET_RECOMMENDED_PEOPLE,
      {},
    );
    if (response?.status === true) {
      const peopleData = response?.response[0];
      yield put(actions.setRecommendedPeople(peopleData));
      // yield put(flurryActions.callFlurryEvent(
      //   valueToObject('executives_page_load', startTime),
      // ));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getExportFollowPeople(dispatched: PayloadAction<ExportFollowRequest>) {
  try {
    const { payload } = dispatched;
    const data = {
      ...payload.filters && { filters: payload.filters?.map((it: Filter) => it.filterId).join(',') },
      ...payload.query && { query: payload.query },
    };
    const response: ExportFollowPeopleResponse = yield call(
      axios.post,
      API.GET_EXPORT_FOLLOWED_PEOPLE,
      data,
    );
    yield put(actions.setExportFollowedPeople(response.response));
  } catch (err) {
    console.log(err);
  }
}

export function* peopleSaga() {
  yield takeLatest(actions.getFollowedPeople.type, getFollowedPeople);
  yield takeLatest(actions.getRecommendedPeople.type, getRecommendedPeople);
  yield takeLatest(actions.getExportFollowedPeople, getExportFollowPeople);
}
