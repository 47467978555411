import { Tooltip } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  primaryFont, wMedium, maxWidth, retina,
} from 'Utils/utilsStyle';

type Props = {
  icon: ReactNode,
  title: string,
  className?: string,
  showTooltip?: boolean,
};

const Span = styled.span`
  display: inline-block;
  font-size: 15px;
  line-height: 20px;
  font-weight: ${wMedium};
  color: #000000;
  font-family: ${primaryFont};
  display: flex;
  align-items: start;
  margin-bottom: 8px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    font-size: 13px;
    line-height: 18px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  span {
    margin-top: 2px;
  }
  svg {
   font-size: 20px;
   margin-right: 10px;
   min-width: 20px;
  }
`;

const defaultProps = {
  className: '',
  showTooltip: false,
};

const ListItem = function ListItem(props: Props) {
  const {
    icon, title, className, showTooltip,
  } = props;
  return (
    <Span className={`tip-item-c ${className ?? ''}`}>
      {icon}
      {showTooltip ? (
        <Tooltip
          placement="topLeft"
          getPopupContainer={(e: HTMLElement) => e}
          className="triggers-bullet-tooltip"
          color="#fff"
          title={title}
        >
          <span className="text-truncate-two-line">{title}</span>
        </Tooltip>
      ) : (
        <span className="text-truncate-two-line">{title}</span>
      )}
    </Span>
  );
};

ListItem.defaultProps = defaultProps;

export default ListItem;
